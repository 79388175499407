import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {connectWallet, getAccountBalance, getCurrentWalletConnected, setChainToAccount, getDefaultChain, getGasPrice} from "../../services/interact";


export interface WalletState {
    isConnected?: boolean,
    address: string,
    balance: number | string,
    network: string,
    status: string,
    gas: string,
}

const initialState: WalletState = {
    isConnected: false,
    address: '',
    balance: 0,
    network: '',
    status: '',
    gas: ''
}

export const connectAddress = createAsyncThunk('wallet/connectAddress', async () => {
    const response = await connectWallet();
    return response;
});

export const getAddress = createAsyncThunk('wallet/getAddress', async () => {
    const response = await getCurrentWalletConnected();
    return response.address;
});

export const getBalance = createAsyncThunk('wallet/getBalance', async (address: string) => {
    const response = await getAccountBalance(address);
    return Number(response.balance);
});

export const changeNetwork = createAsyncThunk('wallet/changeNetwork', async (chain: object) => {
    const response = await setChainToAccount(chain);
    return response.network;
});

export const getDefaultNetwork = createAsyncThunk('wallet/getDefaultNetwork', async () => {
    const response = await getDefaultChain();
    return String(response.network);
});

export const getGas = createAsyncThunk('wallet/getGas', async () => {
    const response = await getGasPrice();
    return String(response.gas);
});

export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setWalletStatus: (state, action: PayloadAction<boolean>) => {
            state.isConnected = action.payload;
        },
        setWalletAddress: (state: any, action: PayloadAction<any>) => {
            state.address = action.payload;
        },
        setWalletBalance: (state, action: PayloadAction<number>) => {
            state.balance = action.payload;
        },
        setWalletNetwork: (state, action: PayloadAction<string>) => {
            state.network = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(connectAddress.fulfilled, (state, action: PayloadAction<object | any>) => {
                state.address = action.payload.address;
                state.status = action.payload.status;
            })
            .addCase(connectAddress.rejected, (state, action: PayloadAction<object | any>) => {
                state.status = action.payload.status;
            })
            .addCase(getAddress.fulfilled, (state, action: PayloadAction<string>) => {
                state.address = action.payload;
            })
            .addCase(getBalance.fulfilled, (state, action: PayloadAction<number>) => {
                state.balance = action.payload;
            })
            .addCase(changeNetwork.fulfilled, (state, action: PayloadAction<string>) => {
                state.network = action.payload;
            })
            .addCase(getDefaultNetwork.fulfilled, (state, action: PayloadAction<string>) => {
                state.network = action.payload;
            })
            .addCase(getGas.fulfilled, (state, action: PayloadAction<string>) => {
                state.gas = action.payload;
            })
    }
})

// Action creators are generated for each case reducer function
export const { setWalletAddress, setWalletNetwork ,setWalletBalance} = walletSlice.actions

export default walletSlice.reducer