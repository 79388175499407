// import {useEffect, useState} from "react";
// import {SignClient} from "@walletconnect/sign-client";
// import {Web3Modal} from "@web3modal/standalone";
// import {Avatar, Button, Typography} from '@mui/material';
// import walletConnectIcon from "../../assets/images/walletConnect.svg"
//
// const web3Modal = new Web3Modal({
// // @ts-ignore
//   projectId: process.env.REACT_APP_CONNECT_WALLET_PROJECT_ID,
//   standaloneChains: ["eip155:56", "eip155:1"],
// });
//
// function WalletConnect() {
//   const [signClientState, setSignClientState] = useState();
//   const [session, setSession] = useState([]);
//   const [account, setAccount] = useState([]);
//   const [txnUrl, setTxnUrl] = useState();
//
//   async function createClient() {
//     try {
//       const signClient: any = await SignClient.init({
//         projectId: process.env.REACT_APP_CONNECT_WALLET_PROJECT_ID,
//       });
//       setSignClientState(signClient);
//       await subscribeToEvents(signClient);
//     } catch (e) {
//       console.log(e);
//     }
//   }
//
//   async function handleConnect() {
//     if (!signClientState) throw Error("Client is not set");
//     try {
//       const proposalNamespace = {
//         eip155: {
//           methods: ["eth_sendTransaction"],
//           chains: ["eip155:5"],
//           events: ["connect", "disconnect"],
//         },
//       };
//
//       // @ts-ignore
//       const { uri, approval } = await signClientState.connect({
//         requiredNamespaces: proposalNamespace,
//       });
//
//       if (uri) {
//         await web3Modal.openModal({uri});
//         const sessionNamespace = await approval();
//         await onSessionConnected(sessionNamespace);
//         web3Modal.closeModal();
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   }
//
//   async function onSessionConnected(session : any) {
//     try {
//       setSession(session);
//       setAccount(session.namespaces.eip155.accounts[0].slice(9));
//     } catch (e) {
//       console.log(e);
//     }
//   }
//
//   async function handleDisconnect() {
//     try {
//       // @ts-ignore
//       await signClientState.disconnect({
//       // @ts-ignore
//         topic: session.topic,
//         message: "User disconnected",
//         code: 6000,
//       });
//       reset();
//     } catch (e) {
//       console.log(e);
//     }
//   }
//
//   async function subscribeToEvents(client : any) {
//     if (!client)
//       throw Error("Unable to subscribe to events. Client does not exist.");
//     try {
//       client.on("session_delete", () => {
//         console.log("The user has disconnected the session from their wallet.");
//         reset();
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   }
//
//   async function handleSend() {
//     if (!account.length) throw Error("No account found");
//     try {
//       const tx = {
//         from: account,
//         to: "0xBDE1EAE59cE082505bB73fedBa56252b1b9C60Ce",
//         data: "0x",
//         gasPrice: "0x029104e28c",
//         gasLimit: "0x5208",
//         value: "0x00",
//       };
//
//       // @ts-ignore
//       const result = await signClientState.request({
//       // @ts-ignore
//         topic: session.topic,
//         chainId: "eip155:5",
//         request: {
//           method: "eth_sendTransaction",
//           params: [tx],
//         },
//       });
//       setTxnUrl(result);
//     } catch (e) {
//       console.log(e);
//     }
//   }
//
//   const reset = () => {
//     setAccount([]);
//     setSession([]);
//   };
//
//   useEffect(() => {
//     if (!signClientState) {
//       createClient().then(r => console.log('r =>' , r));
//     }
//   }, [signClientState]);
//
//   return (
//     <div className="WalletConnect">
//       {account.length ? (
//         <>
//           <p>{account}</p>
//           <button onClick={handleSend}>Send Transaction</button>
//           <button onClick={handleDisconnect}>Disconnect</button>
//           {txnUrl && (
//             <h2>
//               Check out your transaction{" "}
//               <a
//                 href={`https://goerli.etherscan.io/tx/${txnUrl}`}
//                 target="_blank"
//               >
//                 here
//               </a>
//               !
//             </h2>
//           )}
//         </>
//       ) : (
//
//           <Button size="large" onClick={handleConnect} disabled={!signClientState} fullWidth variant="contained"
//                   disableElevation color="grey7"
//                   sx={{borderRadius: "12px", py: 2, justifyContent: "start"}}><Typography
//               sx={{
//                 display: "flex",
//                 justifyContent: "start",
//                 alignItems: "center",
//                 mb: 0,
//                 color: 'black.main',
//                 fontsize: 12,
//                 fontWeight: 700
//               }} color="white">
//             <Avatar
//                 alt=""
//                 src={walletConnectIcon}
//                 sx={{
//                   width: 32, height: 32, mr: 1
//                 }}
//             /> WalletConnect
//           </Typography>
//           </Button>
//       )}
//     </div>
//   );
// }
//
// export default WalletConnect;
import {Avatar, Button, Typography} from '@mui/material';
import walletConnectIcon from "../../assets/images/walletConnect.svg"
import {useWeb3Modal, Web3Modal} from "@web3modal/react";
import {configureChains, createConfig, useAccount, useDisconnect, WagmiConfig} from "wagmi";
import {EthereumClient, w3mConnectors, w3mProvider,} from "@web3modal/ethereum";
import {useEffect, useState} from "react";
import {mainnet, optimism, polygon} from "wagmi/chains";
import CircularProgress from '@mui/material/CircularProgress';

// 1. Get projectID at https://cloud.walletconnect.com
if (!process.env.REACT_APP_CONNECT_WALLET_PROJECT_ID) {
  throw new Error("You need to provide REACT_APP_CONNECT_WALLET_PROJECT_ID env variable");
}
const projectId = process.env.REACT_APP_CONNECT_WALLET_PROJECT_ID;

// 2. Configure wagmi client
const chains = [mainnet, polygon, optimism];

const {publicClient} = configureChains(chains, [w3mProvider({projectId})]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({chains, projectId}),
  publicClient,
});
// 3. Configure modal ethereum client
const ethereumClient = new EthereumClient(wagmiConfig, chains);

// 4. Wrap your app with WagmiProvider and add <Web3Modal /> compoennt

export default function WalletConnect() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
      <>
        {ready ? (
              <CustomButton/>
        ) : null}

        <Web3Modal themeMode="light" projectId={projectId} ethereumClient={ethereumClient}/>
      </>
  );
}

function CustomButton() {
  const [loading, setLoading] = useState(false);
  const {open} = useWeb3Modal();
  const {isConnected} = useAccount();
  const {disconnect} = useDisconnect();

  async function onOpen() {
    setLoading(true);
    await open();
    setLoading(false);
  }

  function onClick() {
    if (isConnected) {
      disconnect();
    } else {
      onOpen().then();
    }
  }

  return (
      <>
        <Button size="large" onClick={onClick} fullWidth variant="contained" disabled={true}
                disableElevation color="grey7"
                sx={{borderRadius: "12px", py: 2, justifyContent: "start"}}><Typography
            sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                mb: 0,
                color: 'black.main',
                fontsize: 12,
                fontWeight: 700
            }} color="white">
            <Avatar
                alt=""
                src={walletConnectIcon}
                sx={{
                    width: 32, height: 32, mr: 1
                }}
            /> WalletConnect <span style={{
            fontSize: 12, color: "#666", marginLeft: 8
        }}>Coming soon</span>
            {loading ? (
                <CircularProgress size={24} color="primary" sx={{
                    ml: 1
                }}/>
            ) : null}
        </Typography>
        </Button>
      </>
  );
}