import React, {FC} from "react";
import {useNavigate} from "react-router-dom";
import ether from "../../assets/images/etherium.png";
import moonbeam from "../../assets/images/moonbeam.png";
import bsc from "../../assets/images/bsc.png";
import {Clear, KeyboardArrowDown} from '@mui/icons-material';
import logo from "../../assets/logo/logo-re.svg";
import metamaskIcon from "../../assets/images/MetaMask.png"
import walletIcon from "../../assets/images/wallet.svg";
import NetworkType from "../../models/network.model";
import WalletType from "../../models/wallet.model";
import {useAccount, useBalance} from 'wagmi'
import {
    changeNetwork,
    connectAddress,
    getBalance,
    getGas,
    setWalletAddress,
    setWalletBalance,
    setWalletNetwork
} from "../../store/features/walletSlice";

import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../store/type'
import {switchChain} from "../../services/interact";
import {styled} from "@mui/material/styles";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import WalletConnect from "../../components/Header/walletConnect";

declare var window: any;

const mainNetList: NetworkType[] = [
    {
        name: "Ethereum",
        caption: "mainnet",
        icon: ether,
        chainId: "0x1",
        networkName: 'Ethereum Mainnet',
        nativeCurrency: {name: 'Ethereum', symbol: 'ETH', decimals: 18},
        rpcUrls: ['https://mainnet.infura.io/v3/'],
        blockExplorerUrls: ['https://etherscan.io'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_ETH,
        bridgeContractAddress: process.env.REACT_APP_BRIDGE_ADDRESS_ETH,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_ETH
    },
    {
        name: "BSC",
        caption: "mainnet",
        icon: bsc,
        chainId: "0x38",
        networkName: 'Binance Smart Chain',
        nativeCurrency: {name: 'Binance Coin', symbol: 'BNB', decimals: 18},
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_BSC,
        bridgeContractAddress: process.env.REACT_APP_BRIDGE_ADDRESS_BSC,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_BSC
    },
    {
        name: "Moonbeam",
        caption: "mainnet",
        icon: moonbeam,
        chainId: "0x504",
        networkName: 'Moonbeam',
        nativeCurrency: {name: 'Glimmer', symbol: 'GLMR', decimals: 18},
        rpcUrls: ['https://rpc.api.moonbeam.network'],
        blockExplorerUrls: ['https://moonscan.io'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_MOONBEAM,
        bridgeContractAddress: process.env.REACT_APP_BRIDGE_ADDRESS_MOONBEAM,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_MOONBEAM
    }
];

const walletsList: WalletType[] = [
    {name: "Install Metamask", link: "https://metamask.io/download.html", icon: metamaskIcon}
];

const LinkItem = styled('a')(() => ({
    textDecoration: "none"
}));

const Header: FC<any> = () => {
    const navigate = useNavigate();
    const walletConnectAddress = useAccount().address;
    const walletConnectBalance = useBalance({
        address: walletConnectAddress,
    });
    const walletConnectBalanceValue = walletConnectBalance?.data?.formatted
    console.log(walletConnectBalanceValue)
    const address = useAppSelector((state) => state.wallet.address);
    const network = useAppSelector((state) => state.wallet.network);
    const balance = useAppSelector((state) => state.wallet.balance)
    const dispatch = useAppDispatch();
    const [networkList, setNetworkList] = React.useState<NetworkType[]>([]);
    const [mainNetwork, setMainNetwork] = React.useState<NetworkType | any>({});
    const [openNetworkModal, setOpenNetworkModal] = React.useState(false);
    const [openWalletsModal, setOpenWalletsModal] = React.useState(false);
    const [walletAddressState, setWalletAddressState] = React.useState("");
    const [openWarningModal, setOpenWarningModal] = React.useState(false);
    const [openConnectWalletModal, setOpenConnectWalletModal] = React.useState(false);
    React.useEffect(() => {
        if (walletConnectAddress) {
            const walletResponseAddress = walletConnectAddress.substring(0, 6) + "..." + walletConnectAddress.substring(38);
            setWalletAddressState(walletResponseAddress);
            dispatch(setWalletAddress(walletConnectAddress));
        }
        if (walletConnectBalanceValue) {
            dispatch(setWalletBalance(parseFloat(walletConnectBalanceValue)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        setNetworkList(mainNetList);
        setMainNetwork(mainNetList[0]);
        changeChain();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (address) {
            const walletResponseAddress = address.substring(0, 6) + "..." + address.substring(38);
            setWalletAddressState(walletResponseAddress);
            dispatch(getBalance(address));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);
    React.useEffect(() => {
        if (network) {
            const defaultNetwork = mainNetList.find(item => item.chainId === network);
            if (defaultNetwork) {
                setOpenWarningModal(false);
                setMainNetwork(defaultNetwork);
                dispatch(getBalance(address));
                dispatch(getGas());
            } else {
                setOpenWarningModal(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network])
    const changeChain = async () => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', (chainId: any) => {
                dispatch(setWalletNetwork(chainId));
            });
        }
    };
    const handleConnectWalletModal = () => {
        setOpenConnectWalletModal(!openConnectWalletModal)
    };
    const handleNetworkModal = () => {
        setOpenNetworkModal(!openNetworkModal)
    };
    const handleWalletsModal = () => {
        setOpenWalletsModal(!openWalletsModal)
    }
    const handleNetworkListItemClick = async (token: object) => {
        const data = await switchChain(token);
        setOpenNetworkModal(!openNetworkModal);
        if (data.code === 4902) {
            dispatch(changeNetwork(token))
        }
    };
    const handleFilterNetwork = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== '') {
            const filteredNetworkData = mainNetList.filter((item: object) => {
                return Object.values(item).join('').toLowerCase().includes(event.target.value.toLowerCase())
            })
            setNetworkList(filteredNetworkData);
        } else {
            setNetworkList(mainNetList);
        }
    }
    const connectWalletPressed = async () => {
        if (!window.ethereum) {
            setOpenWalletsModal(!openWalletsModal)
            setOpenConnectWalletModal(!openConnectWalletModal)
        } else {
            dispatch(connectAddress());
            setOpenConnectWalletModal(!openConnectWalletModal)
        }
    };
    const handleWarningModal = () => {
        setOpenWarningModal(false);
    }
    const navItems = [
        {title: 'Dashboard', link: "/"},
        {title: 'stake', link: "/stake"},
        {title: 'bridge', link: "/bridge"}
    ];
    return (
        <>
            <AppBar component="nav" sx={{bgcolor: 'white.main'}} elevation={1}>
                <Container>
                    <Toolbar sx={{
                        height: '72px',
                        justifyContent: 'space-between',
                        alignItems: "center",
                        pr: {md: "56px"}
                    }}>
                        {/*<IconButton*/}
                        {/*    color="inherit"*/}
                        {/*    aria-label="open drawer"*/}
                        {/*    edge="start"*/}
                        {/*    onClick={handleDrawerToggle}*/}
                        {/*    sx={{mr: 2, display: {sm: 'none'}}}*/}
                        {/*>*/}
                        {/*    <MenuIcon/>*/}
                        {/*</IconButton>*/}
                        <Box sx={{display: 'flex'}}>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    textDecoration: "none"
                                }}
                            >
                                <Avatar alt="" src={logo} sx={{
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: 0,
                                    mr: 1
                                }}/> <Typography component="span" color="#03818C"
                                                 sx={{
                                                     color: "#03818C",
                                                     fontSize: "18px",
                                                     fontWeight: "bold",
                                                     marginRight: "26px",
                                                     textDecoration: "none",
                                                     display: {xs: "none", md: "inline"}
                                                 }}>HPOtoken</Typography>
                            </Typography>
                            <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                                {navItems.map((item) => (
                                    <Button color={window.location.pathname === item.link ? 'primary5' : 'grey6' } key={item.title}
                                            onClick={() => navigate(item.link)}>
                                        {item.title}
                                    </Button>
                                ))}
                            </Box>
                        </Box>

                        <Box sx={{display: 'flex'}}>
                            <Button variant="contained"
                                    color={'grey8'}
                                    sx={{
                                        borderRadius: '6px',
                                        display: {xs: "none", md: "inline-flex", marginRight: '20px'}
                                    }}
                                    disableElevation
                                    endIcon={<KeyboardArrowDown sx={{
                                        color: "primary5.main",
                                    }}/>}
                                    onClick={handleNetworkModal}
                            >
                                <Avatar
                                    alt=""
                                    src={mainNetwork.icon}
                                    sx={{
                                        mr: 0.5,
                                        width: "20px",
                                        height: "20px",
                                    }}
                                />
                            </Button>
                            {address ? <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: '10px',
                                    borderRadius: '8px',
                                    backgroundColor: 'grey8.main',
                                    padding: '0 10px',
                                    height: '36px',
                                    color: "primary6.main"
                                }}>{walletAddressState}</Box>
                                : <Button variant="contained"
                                          color={'primary5'}
                                          sx={{
                                              borderRadius: '8px',
                                              ml: '8px',
                                              boxShadow: 0,
                                              color: "black",
                                              fontSize: "14px",
                                              decoration: "lowercase",
                                              textTransform: "none !important"
                                          }}
                                          onClick={handleConnectWalletModal}
                                >Connect to wallet</Button>}
                        </Box>
                        <Dialog
                            open={openNetworkModal}
                            onClose={handleNetworkModal}
                            sx={{
                                "& .MuiPaper-root": {
                                    width: 420,
                                    maxWidth: 420,
                                    maxHeight: "80vh",
                                    bgcolor: 'grey8.main',
                                    color: "white",
                                    borderRadius: "20px",
                                }
                            }}
                        >
                            <DialogTitle>
                                <Typography
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                    <div>Switch to</div>
                                    <Clear sx={{fontSize: "24px", cursor: "pointer"}} onClick={handleNetworkModal}/>
                                </Typography>
                                <TextField placeholder="Switch to" onChange={handleFilterNetwork} sx={{
                                    width: "100%",
                                    pt: "12px",
                                    "& .MuiInputBase-root": {
                                        "& .MuiInputBase-input": {color: "white"},
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "20px",
                                            borderColor: "white"
                                        }
                                    },
                                    "& .Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderWidth: "1px !important",
                                            borderColor: "white !important"
                                        },
                                    }
                                }}/>
                            </DialogTitle>
                            <DialogContent dividers sx={{
                                borderColor: "white",
                                p: "0",
                                '&::-webkit-scrollbar': {display: 'none'}
                            }}>
                                <List sx={{pt: 0}}>
                                    {networkList.map((token, index) => (
                                        <ListItem sx={{
                                            justifyContent: "space-between",
                                            pr: "24px",
                                            pl: "24px",
                                            borderBottom: "1px solid",
                                            borderBottomColor: "grey10.main",
                                            "&:hover": {backgroundColor: "grey9.main"}
                                        }} button onClick={() => handleNetworkListItemClick(token)} key={index}>
                                            <Box sx={{display: "flex", alignItems: "center"}}>
                                                <Avatar
                                                    alt=""
                                                    src={token.icon}
                                                    sx={{
                                                        bgcolor: "white.main",
                                                        marginRight: "15px"
                                                    }}
                                                />
                                                <Box sx={{
                                                    width: '8px',
                                                    height: '8px',
                                                    borderRadius: '50%',
                                                    backgroundColor: 'primary6.main',
                                                    mr: '6px'
                                                }}/>
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    sx={{
                                                        color: "white",
                                                        fontSize: "1rem",
                                                        fontWeight: 400
                                                    }}
                                                >{token.name}</Typography>
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    sx={{
                                                        color: "grey5.main",
                                                        fontSize: "0.8rem",
                                                        fontWeight: 400,
                                                        ml: 1
                                                    }}
                                                >{token.caption}</Typography>
                                            </Box>
                                        </ListItem>
                                    ))}
                                </List>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={openWalletsModal}
                            onClose={handleWalletsModal}
                            sx={{
                                "& .MuiPaper-root": {
                                    width: 420,
                                    maxWidth: 420,
                                    minHeight: "300px",
                                    bgcolor: 'grey9.main',
                                    color: "white",
                                    borderRadius: "20px",
                                }
                            }}
                        >
                            <DialogTitle>
                                <Typography component="div"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                    <div>Connect to wallet</div>
                                    <Clear sx={{fontSize: "24px", cursor: "pointer"}} onClick={handleWalletsModal}/>
                                </Typography>
                            </DialogTitle>
                            <DialogContent dividers sx={{
                                borderColor: "white",
                                p: "0",
                                '&::-webkit-scrollbar': {display: 'none'}
                            }}>
                                <List sx={{pt: 0}}>
                                    {walletsList.map((wallet, index) => (
                                        <LinkItem target="_blank" href={wallet.link} key={index}>
                                            <ListItem sx={{
                                                justifyContent: "space-between",
                                                pr: "24px",
                                                pl: "24px",
                                                borderBottom: "1px solid",
                                                borderBottomColor: "grey10.main",
                                                "&:hover": {backgroundColor: "grey11.main"}
                                            }}>
                                                <Box sx={{display: "flex", alignItems: "center"}}>
                                                    <Avatar
                                                        alt=""
                                                        src={wallet.icon}
                                                        sx={{
                                                            bgcolor: "white",
                                                            marginRight: "15px"
                                                        }}
                                                    />
                                                    <Box sx={{
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'success.main',
                                                        mr: '6px'
                                                    }}/>
                                                    <Typography
                                                        variant="caption"
                                                        component="div"
                                                        sx={{
                                                            color: "black.main",
                                                            fontSize: "1rem",
                                                            fontWeight: 400
                                                        }}
                                                    >{wallet.name}</Typography>
                                                </Box>
                                            </ListItem>
                                        </LinkItem>
                                    ))}
                                </List>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={openWarningModal}
                            sx={{
                                "& .MuiPaper-root": {
                                    width: 420,
                                    maxWidth: 420,
                                    bgcolor: 'grey9.main',
                                    color: "white",
                                    borderRadius: "20px",
                                }
                            }}
                        >
                            <DialogContent sx={{textAlign: "center"}}>
                                <GppMaybeIcon sx={{fontSize: "60px", color: "secondary.main"}}/>
                                <Typography variant="h6" component="h6">Unsupported Network</Typography>
                                <Typography variant="caption" component="p" sx={{mt: 3, fontSize: "14px"}}>The selected
                                    network in
                                    your metamask wallet is not supported. Please switch to one of those networks: BSC,
                                    ETHEREUM or
                                    MOONBEAM.</Typography>
                                <Button variant="contained" onClick={handleWarningModal} sx={{
                                    bgcolor: "grey10.main",
                                    color: "primary.main",
                                    width: 250,
                                    height: 48,
                                    borderRadius: 20,
                                    mt: 3,
                                    fontWeight: "bold",
                                    "&:hover": {
                                        bgcolor: "secondary.main"
                                    }
                                }}>Confirm</Button>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={openConnectWalletModal}
                            sx={{
                                "& .MuiPaper-root": {
                                    width: 420,
                                    maxWidth: 420,
                                    bgcolor: 'grey9.main',
                                    color: "white",
                                    borderRadius: "20px",
                                }
                            }}
                        ><DialogTitle sx={{display: "flex", justifyContent: "end", pb:0}}>
                            <Clear sx={{fontSize: "24px", cursor: "pointer"}} onClick={() => handleConnectWalletModal()}/>
                        </DialogTitle>
                            <DialogContent sx={{textAlign: "center"}}>
                                <Avatar
                                    alt=""
                                    src={walletIcon}
                                    sx={{
                                        width: 56, height: 56, mx: "auto", mb: 1
                                    }}
                                />
                                <Typography sx={{fontSize: 16, fontWeight: 500}} color="#21242B" gutterBottom>
                                    Connect wallet
                                </Typography>

                                <Typography sx={{fontSize: 14, mb: 3}} color="text.secondary">
                                    Please connect your wallet To start staking.
                                </Typography>

                                <Button size="large" onClick={connectWalletPressed} fullWidth variant="contained" disableElevation color="grey7"
                                        sx={{borderRadius: "12px", py: 2, mb: 1, justifyContent: "start"}}><Typography
                                    sx={{display: "flex", justifyContent: "start", alignItems: "center",mb: 0, color: 'black.main', fontsize: 12, fontWeight: 700}} color="white">
                                   <Avatar
                                    alt=""
                                    src={metamaskIcon}
                                    sx={{
                                        width: 32, height: 32, mr: 1
                                    }}
                                /> Metamask
                                </Typography>
                                </Button>
                                <WalletConnect/>

                                {/*<List sx={{py: 0}}>
                                    <ListItem sx={{
                                        justifyContent: "space-between",
                                        pr: "24px",
                                        pl: "24px",
                                        borderBottom: "1px solid",
                                        borderBottomColor: "grey10.main",
                                        "&:hover": {backgroundColor: "grey9.main"}
                                    }} button onClick={connectWalletPressed}>
                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                            <Avatar
                                                alt=""
                                                src=""
                                                sx={{
                                                    bgcolor: "white.main",
                                                    marginRight: "15px"
                                                }}
                                            />
                                            <Box sx={{
                                                width: '8px',
                                                    height: '8px',
                                                    borderRadius: '50%',
                                                    backgroundColor: 'primary6.main',
                                                    mr: '6px'
                                                }}/>
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    sx={{
                                                        color: "white",
                                                        fontSize: "1rem",
                                                        fontWeight: 400
                                                    }}
                                                >gfh</Typography>
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    sx={{
                                                        color: "grey5.main",
                                                        fontSize: "0.8rem",
                                                        fontWeight: 400,
                                                        ml: 1
                                                    }}
                                                >asd</Typography>
                                            </Box>
                                        </ListItem>

                                </List>*/}
                            </DialogContent>
                        </Dialog>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}

export default Header;