import React, {FC} from 'react';
import {BottomNavigation, BottomNavigationAction} from '@mui/material';
import {routes} from "../../configs/routes";
import {useNavigate} from "react-router-dom";


const MobileNavigation: FC = () => {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();

    return (
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            sx={{width: '100%', position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#F5F8F7'}}
        >
            {
                routes.map(route => (
                    <BottomNavigationAction
                        label={route.title}
                        key={route.key}
                        onClick={() => navigate(route.path)}
                        sx={{
                            color: '#719D91',
                            '&.MuiBottomNavigationAction-root.Mui-selected': {
                                color: 'primary1.main',
                            },
                            '& .Mui-selected': {color: 'primary1.main'}
                        }}/>
                ))
            }
        </BottomNavigation>
    );
}

export default MobileNavigation;
