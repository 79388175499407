import {createTheme} from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    '&.MuiContainer-maxWidthLg': {
                        maxWidth: 1440,
                    },
                },
            },
        },
    },
    direction: 'ltr',
    palette: {
        black: {main: '#000000'},
        white: {main: '#ffffff'},
        primary1: {main: '#152E27'},
        primary2: {main: '#1A3931'},
        primary3: {main: '#245045'},
        primary4: {main: '#2C6253'},
        primary5: {main: '#347362'},
        primary6: {main: '#52887A'},
        secondary1: {main: '#5F5120'},
        secondary2: {main: '#766528'},
        secondary3: {main: '#A68E38'},
        secondary4: {main: '#C9AD44'},
        secondary5: {main: '#EDCB50'},
        secondary6: {main: '#EFD36A'},
        secondary7: {main: '#F3DB85'},
        secondaryX: {main: '#F6E5A8'},
        grey1: {main: '#111213'},
        grey2: {main: '#1E1F22'},
        grey3: {main: '#1E1F22'},
        grey4: {main: '#484A4F'},
        grey5: {main: '#696C75'},
        grey6: {main: '#9799A1'},
        grey7: {main: '#DADBDE'},
        grey8: {main: '#EDEDEE'},
        grey9: {main: '#F3F3F4'},
        grey10: {main: '#F6F6F7'},
        grey11: {main: '#FCFCFC'},
        grey12: {main: '#C2D5D0'},
        grey13: {main: '#BDE0D7'},
        grey14: {main: '#98eef1'},
        primary: {main: '#188E92'},
        secondary: {main: '#EDCB50'},
        error: {main: '#F04438'},
        success: {main: '#16B364'},
        info: {main: '#2ABCEB'},
        warning: {main: '#EAAA08'},
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1440,
            xl: 1536,
        },
    }
});

export default theme;
