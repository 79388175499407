import React, {FC, ReactElement} from "react";
import hippo from "../../assets/images/hpo.png";
import {Clear, FiberManualRecord, InfoOutlined, KeyboardArrowDown} from '@mui/icons-material';
import bsc from "../../assets/images/bsc.png";
import CurrencyType from "../../models/currency.model"
import {useAppDispatch, useAppSelector} from "../../store/type";
import {approve, getPlans, getTokenBalance, stake, stake2, switchChain, waitTransaction} from "../../services/interact";
import {changeNetwork} from "../../store/features/walletSlice";
import stakeNetworkType from "../../models/stakeNetwork.model";
import ether from "../../assets/images/etherium.png";
import SyncSharpIcon from '@mui/icons-material/SyncSharp';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';
import stakePlanType from "../../models/stakePlan.model";
import {styled} from '@mui/material/styles';

import {
    Alert,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    Radio,
    RadioGroup,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {RadioProps} from '@mui/material/Radio';

const BpIcon = styled('span')(({theme}) => ({
    borderRadius: '50%',
    width: 20,
    height: 20,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F8F7',
    '.Mui-focusVisible &': {
        outline: '1px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#347362',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    '&.Mui-checked ~': {
        border: '1px solid #347362'
    },
    'input:hover ~ &': {
        backgroundColor: '#347362',
    },
});

function BpRadio(props: RadioProps) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon/>}
            icon={<BpIcon/>}
            {...props}
        />
    );
}

const mainNetList: stakeNetworkType[] = [
    {
        name: "Ethereum",
        caption: "mainnet",
        icon: ether,
        chainId: "0x1",
        networkName: 'Ethereum Mainnet',
        nativeCurrency: {name: 'Ethereum', symbol: 'ETH', decimals: 18},
        rpcUrls: ['https://mainnet.infura.io/v3/'],
        blockExplorerUrls: ['https://etherscan.io'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_ETH,
        stakeContractAddress: process.env.REACT_APP_STAKE_ADDRESS_ETH,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_ETH
    },
    {
        name: "BSC",
        caption: "mainnet",
        icon: bsc,
        chainId: "0x38",
        networkName: 'Binance Smart Chain',
        nativeCurrency: {name: 'Binance Coin', symbol: 'BNB', decimals: 18},
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_BSC,
        stakeContractAddress: process.env.REACT_APP_STAKE_ADDRESS_BSC2,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_BSC
    }
];

const coinList: CurrencyType[] = [
    {iso: "HPO", name: "Hippopotamus", icon: hippo}
]

const Stake: FC<any> = (): ReactElement => {
    const address = useAppSelector((state) => state.wallet.address);
    const network = useAppSelector((state) => state.wallet.network);
    const gasPrice = useAppSelector((state) => state.wallet.gas);
    const balance = useAppSelector((state) => state.wallet.balance);
    const dispatch = useAppDispatch();
    const [approveOpenToast, setApproveOpenToast] = React.useState(false);
    const [depositOpenToast, setDepositOpenToast] = React.useState(false);
    const [fromAmount, setFromAmount] = React.useState('');
    const [accountBalance, setAccountBalance] = React.useState('');
    const [networkList, setNetworkList] = React.useState<stakeNetworkType[]>([]);
    const [currencyList, setCurrencyList] = React.useState<CurrencyType[]>([]);
    const [mainNetwork, setMainNetwork] = React.useState<stakeNetworkType | any>({});
    const [mainCurrency, setMainCurrency] = React.useState<CurrencyType>({});
    const [openCurrencyModal, setOpenCurrencyModal] = React.useState(false);
    const [openNetworkModal, setOpenNetworkModal] = React.useState(false);
    const [approveStatus, setApproveStatus] = React.useState<object | any>({status: "approve", code: 0});
    const [stakeStatus, setStakeStatus] = React.useState<object | any>({status: "deposit", code: 0});
    const [fromAmountErrorState, setFromAmountErrorState] = React.useState({errorStatus: false, errorComment: ''});
    const [openConfirmTransaction, setOpenConfirmTransaction] = React.useState(false);
    const [statusOfConfirmTransaction, setStatusOfConfirmTransaction] = React.useState(false);
    const [stakePlanData, setStakePlanData] = React.useState<stakePlanType[] | any>([]);
    const [stakePlanRadio, setStakePlanRadio] = React.useState(0);
    React.useEffect(() => {
        setNetworkList(mainNetList);
        setMainNetwork(mainNetList[0]);
        setCurrencyList(coinList);
        setMainCurrency(coinList[0]);
        if (network === "0x504") {
            handleNetworkListItemClick(mainNetList[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (network && network !== "0x504") {
            const defaultNetwork = mainNetList.find(item => item.chainId === network)!;
            if (defaultNetwork) {
                setMainNetwork(defaultNetwork);
                getAccountBalance(address, defaultNetwork.smartContractAddress! as string);
                if (defaultNetwork.chainId === "0x38") {
                    getStakePlan(defaultNetwork.stakeContractAddress! as string);
                } else if (defaultNetwork.chainId === "0x1") {
                    const planData: stakePlanType[] = [];
                    planData.push({
                        id: "0",
                        ratio: "6%",
                        minAmount: thousandMask("10000"),
                        releaseDuration: "30 DAY"
                    })
                    setStakePlanData(planData);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network, address]);
    const getStakePlan = async (stakeAddress: string | any) => {
        const data = await getPlans(stakeAddress);
        const planData: stakePlanType[] = [];
        data?.forEach((item: any, index: any) => {
            if (Number(item[0]) !== 0) {
                planData.push({
                    id: index,
                    ratio: String(Number(item[1]) / 100) + "%",
                    minAmount: thousandMask(String(item[2] / Math.pow(10, 18))),
                    releaseDuration: String(Number(item[0]) / 86400) + " DAY"
                })
            }
        })
        setStakePlanData(planData);
    }
    const getAccountBalance = async (accountAddress: string, smartContractAddress: string) => {
        if (accountAddress && smartContractAddress) {
            const amount = await getTokenBalance(accountAddress, smartContractAddress);
            const strAmount = String(Number(amount).toFixed(3));
            setAccountBalance(strAmount);
        }
    }
    const removeThousandMask = (number: string) => {
        return number.replace(/,/gi, "");
    }
    const thousandMask = (number: string) => {
        const spl = number && number.split(".");
        if (spl && spl[1]) {
            return numberWithCommas(spl[0]) + "." + spl[1];
        } else {
            return numberWithCommas(number);
        }
    };
    const numberWithCommas = (number: string) => {
        return number && number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dataWithoutThousand = removeThousandMask(event.target.value);
        const dataWithThousand = thousandMask(dataWithoutThousand);
        setFromAmount(dataWithThousand);
        if (!event.target.value) {
            setFromAmountErrorState({errorStatus: true, errorComment: 'Please enter amount.'});
        } else if (Number(removeThousandMask(event.target.value)) > Number(accountBalance)) {
            setFromAmountErrorState({errorStatus: true, errorComment: 'Your balance is not enough.'});
        } else if (Number(balance) < Number(gasPrice)) {
            setFromAmountErrorState({errorStatus: true, errorComment: 'Your main balance is not enough.'});
        } else if (Number(removeThousandMask(event.target.value)) < Number(removeThousandMask(stakePlanData[stakePlanRadio].minAmount))) {
            setFromAmountErrorState({
                errorStatus: true,
                errorComment: `Minimum staking Amount is ${thousandMask(stakePlanData[stakePlanRadio].minAmount)}.`
            });
        } else {
            setFromAmountErrorState({errorStatus: false, errorComment: ''});
        }
    };
    const handleCurrencyModal = () => {
        setOpenCurrencyModal(!openCurrencyModal)
    };
    const handleNetworkModal = () => {
        setOpenNetworkModal(!openNetworkModal);
    };
    const handleCurrencyListItemClick = (token: object) => {
        setMainCurrency(token);
        handleCurrencyModal();
    };
    const handleNetworkListItemClick = async (token: object) => {
        const data = await switchChain(token);
        if (data.code === 4902) {
            dispatch(changeNetwork(token))
        }
        handleNetworkModal()
    };
    const handleFilterCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== '') {
            const filteredData = coinList.filter((item: object) => {
                return Object.values(item).join('').toLowerCase().includes(event.target.value.toLowerCase())
            })
            setCurrencyList(filteredData);
        } else {
            setCurrencyList(coinList);
        }
    }
    const handleFilterNetwork = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== '') {
            const filteredNetworkData = mainNetList.filter((item: object) => {
                return Object.values(item).join('').toLowerCase().includes(event.target.value.toLowerCase())
            })
            setNetworkList(filteredNetworkData);
        } else {
            setNetworkList(mainNetList);
        }
    }
    const onTransferBalancePressed = async () => {
        if (!fromAmountErrorState.errorStatus) {
            const amount = removeThousandMask(fromAmount);
            const approveState = await approve(address, mainNetwork.stakeContractAddress, amount, mainNetwork.smartContractAddress);
            setApproveStatus(approveState);
            setApproveOpenToast(!approveOpenToast);
            if (approveState?.code === 200) {
                setOpenConfirmTransaction(true);
                const receiptTransaction: any = await waitTransaction(approveState?.hash, {
                    interval: 1000,
                    blocksToWait: 2
                });
                if (receiptTransaction?.status === true) {
                    setStatusOfConfirmTransaction(true);
                    if (mainNetwork.chainId === "0x1") {
                        const stakeState = await stake(address, amount, mainNetwork.stakeContractAddress, mainNetwork.blockExplorerUrls[0]);
                        setStakeStatus(stakeState);
                        setDepositOpenToast(!depositOpenToast);
                        setOpenConfirmTransaction(false);
                        setStatusOfConfirmTransaction(false);
                    } else if (mainNetwork.chainId === "0x38") {
                        const stakeState = await stake2(address, amount, mainNetwork.stakeContractAddress, mainNetwork.blockExplorerUrls[0], stakePlanRadio);
                        setStakeStatus(stakeState);
                        setDepositOpenToast(!depositOpenToast);
                        setOpenConfirmTransaction(false);
                        setStatusOfConfirmTransaction(false);
                    }
                }
            }
        }
    }
    const handleCloseApproveToast = async () => {
        setApproveOpenToast(false);
    }
    const handleCloseDepositToast = async () => {
        setDepositOpenToast(false);
    }
    const handleConfirmTransaction = () => {
        setOpenConfirmTransaction(false);
    }
    const handleStakePlanRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStakePlanRadio(Number(event.target.value))
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '1.25rem',
                p: {xs: '24px 16px', sm: "24px 40px 28px"},
            }}>
                <Box sx={{
                    minWidth: {xs: '100%', md: '395px'}, maxWidth: {xs: '100%', md: '395px'}, backgroundColor: "white.main", p: {xs: 0, md: 2},borderRadius:"16px"
                }}>
                    <Typography component="h5" sx={{
                        mb: 1,
                        color: "black",
                        fontSize: "12px"
                    }}>Network</Typography>
                    <FormControl sx={{mb: 1, width: '100%'}}>
                        <Button variant="contained"
                                color={'grey8'}
                                sx={{
                                    height: "56px",
                                    borderRadius: '16px',
                                    display: "flex", justifyContent: 'space-between'
                                }}
                                disableElevation
                                endIcon={<KeyboardArrowDown sx={{
                                    color: "primary5.main",
                                }}/>}
                                onClick={handleNetworkModal}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexGrow: 1,
                                alignItems: 'center',
                            }}>
                                <Avatar
                                    alt=""
                                    src={mainNetwork.icon}
                                    sx={{
                                        mr: 0.5,
                                        width: "20px",
                                        height: "20px",
                                    }}
                                />
                                {mainNetwork.networkName}
                            </Box>
                        </Button>
                    </FormControl>
                    <Typography component="h5" sx={{
                        mb: 1,
                        color: "black",
                        fontSize: "12px"
                    }}>Amount</Typography>
                    <Box sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'warp',
                            flexGrow: 1,
                            alignItems: 'center',
                            backgroundColor: "#F6F6F7",
                            height: '56px',
                            px: 2,
                            borderRadius: '16px 0 0 16px',
                            mb: fromAmountErrorState.errorComment ? 3 : 0
                        }}>
                            <Avatar
                                alt=""
                                src={mainCurrency.icon}
                                sx={{
                                    mr: 0.5,
                                    width: "20px",
                                    height: "20px",
                                }}
                            />
                            {mainCurrency.iso}
                        </Box>
                        <TextField onChange={handleChange} value={fromAmount}
                                   helperText={fromAmountErrorState.errorComment}
                                   error={fromAmountErrorState.errorStatus} placeholder="0.000" fullWidth
                                   variant="outlined" sx={{
                            "& .MuiInputBase-root": {
                                direction: "rtl",
                                borderColor: "white",

                                "& .MuiInputBase-input": {borderColor: "white !important"},
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: '0 16px 16px 0',
                                    borderColor: "#F6F6F7"
                                }
                            },
                            ".MuiFormHelperText-root": {
                                textAlign: 'end',
                            }
                        }}/>
                    </Box>
                    {coinList.length !== 0 && networkList.length !== 0 ? (
                        <Typography component="h5" sx={{
                            mb: 1,
                            textAlign: 'end',
                            color: "primary5.main",
                            fontSize: "12px"
                        }}> Total
                            Balance: {balance} {mainNetwork.nativeCurrency.symbol} / {accountBalance} {mainCurrency.iso}
                        </Typography>
                    ) : ''}
                    <Typography component="h5" sx={{
                        mb: 1,
                        color: "black",
                        fontSize: "12px"
                    }}>Plan</Typography>

                    <FormControl sx={{
                        width: '100%',
                    }}>
                        <RadioGroup
                            row
                            value={stakePlanRadio}
                            onChange={handleStakePlanRadio}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {stakePlanData?.map((item: any, index: any) => (
                                <FormControlLabel
                                    className={stakePlanRadio === index ? 'label-active' : ''}
                                    sx={{
                                        backgroundColor: '#F5F8F7',
                                        width: '100%',
                                        display: 'flex',
                                        mb: 1,
                                        mr: 0,
                                        ml: 0,
                                        p: '12px 8px',
                                        borderRadius: '12px',
                                        color: "white",
                                        "& .MuiTypography-root": {
                                            fontSize: "14px",
                                            fontWeight: "400"
                                        }
                                    }}
                                    key={index}
                                    value={index}
                                    control={<BpRadio/>}
                                    label={item.releaseDuration + ", " + item.ratio}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{
                        border: '1px solid #347362',
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '12px',
                        p: 2.5,
                        mt: 1,
                        mb: 1
                    }}>
                        {stakePlanData.length !== 0 && <Grid container spacing={2}>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                <InfoOutlined sx={{color: "primary5.main"}}/>
                                <Typography component="span"
                                            sx={{
                                                color: "primary5.main",
                                                fontSize: "13px",
                                                fontWeight: 600,
                                                ml:1
                                            }}>Notes:</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{pt: "0px !important"}}>
                                <Typography sx={{
                                    pr: {xs: "16px", md: "0px"},
                                    pl: {xs: "16px", md: "32px"},
                                    fontSize: "13px",
                                    color: "primary5.main"
                                }}><FiberManualRecord
                                    sx={{fontSize: "6px", mr: 1}}/>Staking fee is 0.00% + Network gas fee
                                </Typography>
                                <Typography sx={{
                                    pr: {xs: "16px",md: "0px"},
                                    pl: {xs: "16px", md: "32px"},
                                    fontSize: "13px",
                                    color: "primary5.main"
                                }}><FiberManualRecord
                                    sx={{fontSize: "6px", mr: 1}}/>Minimum staking amount
                                    is {stakePlanData[stakePlanRadio].minAmount} {mainCurrency.iso}
                                </Typography>
                                <Typography sx={{
                                    pr: {xs: "16px", md: "0px"},
                                    pl: {xs: "16px", md: "32px"},
                                    fontSize: "13px",
                                    color: "primary5.main"
                                }}><FiberManualRecord
                                    sx={{fontSize: "6px", mr: 1}}/>Exact time of staking arrival
                                    is {stakePlanData[stakePlanRadio].releaseDuration}</Typography>
                                <Typography sx={{
                                    pr: {xs: "16px", md: "0px"},
                                    pl: {xs: "16px", md: "32px"},
                                    fontSize: "13px",
                                    color: "primary5.main"
                                }}><FiberManualRecord
                                    sx={{fontSize: "6px", mr: 1}}/>Estimated time of staking is less than 1
                                    min</Typography>
                                <Typography sx={{
                                    pr: {xs: "16px", md: "0px"},
                                    pl: {xs: "16px", md: "32px"},
                                    fontSize: "13px",
                                    color: "primary5.main"
                                }}><FiberManualRecord
                                    sx={{fontSize: "6px", mr: 1}}/>Profit of staking
                                    is {stakePlanData[stakePlanRadio].ratio}
                                </Typography>
                            </Grid>
                        </Grid>}
                    </Box>
                    <Button fullWidth variant="contained" color="primary5" onClick={onTransferBalancePressed} sx={{
                        height: 54,
                        borderRadius: "16px",
                        fontWeight: "bold",
                        color: "white.main",
                        boxShadow: 0
                    }}>Stake</Button>
                </Box>
            </Box>

            {/*<Box sx={{*/}
            {/*    backgroundColor: 'primary5.main',*/}
            {/*    display: 'flex',*/}
            {/*    flexGrow: 1,*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    borderRadius: '1.25rem',*/}
            {/*    p: {xs: '24px 16px', sm: "24px 40px 28px"},*/}
            {/*}}>*/}
            {/*    {coinList.length !== 0 && networkList.length !== 0 ? (*/}
            {/*        <Grid container spacing={2}>*/}
            {/*            <Grid item xs={12} sx={{*/}
            {/*                display: "flex",*/}
            {/*                justifyContent: "space-between",*/}
            {/*                color: "white"*/}
            {/*            }}>*/}
            {/*                <div>Amount</div>*/}
            {/*                <div>Balance: {balance} {mainNetwork.nativeCurrency.symbol} / {accountBalance} {mainCurrency.iso}</div>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} md={6}>*/}
            {/*                <TextField*/}
            {/*                    error={fromAmountErrorState.errorStatus}*/}
            {/*                    helperText={fromAmountErrorState.errorComment}*/}
            {/*                    placeholder="0.0"*/}
            {/*                    variant="standard"*/}
            {/*                    value={fromAmount}*/}
            {/*                    onChange={handleChange}*/}
            {/*                    InputProps={{*/}
            {/*                        sx: {*/}
            {/*                            height: {xs: 44, sm: 57, md: 70},*/}
            {/*                            fontSize: "20px",*/}
            {/*                            color: "white",*/}
            {/*                            "&:after": {*/}
            {/*                                borderColor: "primary6.main",*/}
            {/*                            },*/}
            {/*                            "&:before": {*/}
            {/*                                borderColor: "primary6.main",*/}
            {/*                            }*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    sx={{*/}
            {/*                        width: "100%",*/}
            {/*                        "& .MuiInputBase-input": {*/}
            {/*                            fontSize: {xs: "30px", sm: "38px", md: "44px"}*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} sm={6} md={3} onClick={handleCurrencyModal}>*/}
            {/*                <Box sx={{*/}
            {/*                    backgroundColor: 'primary4.main',*/}
            {/*                    border: 1,*/}
            {/*                    borderColor: "primary6.main",*/}
            {/*                    display: 'flex',*/}
            {/*                    justifyContent: 'space-between',*/}
            {/*                    alignItems: 'center',*/}
            {/*                    borderRadius: '1.25rem',*/}
            {/*                    p: '12px',*/}
            {/*                    width: "100%",*/}
            {/*                    height: "100%",*/}
            {/*                    "&:hover": {*/}
            {/*                        bgcolor: "primary2.main",*/}
            {/*                        cursor: "pointer"*/}
            {/*                    }*/}
            {/*                }}>*/}
            {/*                    <Typography component="div" sx={{display: "flex", alignItems: "center"}}>*/}
            {/*                        <Avatar*/}
            {/*                            src={mainCurrency.icon}*/}
            {/*                            sx={{*/}
            {/*                                width: {xs: 30, sm: 38, md: 46},*/}
            {/*                                height: {xs: 30, sm: 38, md: 46},*/}
            {/*                                bgcolor: "white",*/}
            {/*                                marginRight: "4px",*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                        <div>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{*/}
            {/*                                    color: "white",*/}
            {/*                                    fontSize: "1rem",*/}
            {/*                                    fontWeight: 800*/}
            {/*                                }}*/}
            {/*                            >{mainCurrency.iso}</Typography>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{color: "white"}}*/}
            {/*                            >{mainCurrency.name}</Typography>*/}
            {/*                        </div>*/}
            {/*                    </Typography>*/}
            {/*                    <ExpandMore sx={{bgcolor: "primary1.main", color: "white", borderRadius: "50%"}}/>*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} sm={6} md={3} onClick={() => handleNetworkModal()}>*/}
            {/*                <Box sx={{*/}
            {/*                    backgroundColor: 'primary4.main',*/}
            {/*                    border: 1,*/}
            {/*                    borderColor: "primary6.main",*/}
            {/*                    display: 'flex',*/}
            {/*                    justifyContent: 'space-between',*/}
            {/*                    alignItems: 'center',*/}
            {/*                    borderRadius: '1.25rem',*/}
            {/*                    p: '12px',*/}
            {/*                    width: "100%",*/}
            {/*                    height: "100%",*/}
            {/*                    "&:hover": {*/}
            {/*                        bgcolor: "primary2.main",*/}
            {/*                        cursor: "pointer"*/}
            {/*                    }*/}
            {/*                }}>*/}
            {/*                    <Typography component="div" sx={{display: "flex", alignItems: "center"}}>*/}
            {/*                        <Avatar*/}
            {/*                            alt=""*/}
            {/*                            src={mainNetwork.icon}*/}
            {/*                            sx={{*/}
            {/*                                width: {xs: 30, sm: 38, md: 46},*/}
            {/*                                height: {xs: 30, sm: 38, md: 46},*/}
            {/*                                bgcolor: "white",*/}
            {/*                                marginRight: "4px"*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                        <div>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{*/}
            {/*                                    color: "black",*/}
            {/*                                    fontSize: "1rem",*/}
            {/*                                    fontWeight: 800*/}
            {/*                                }}*/}
            {/*                            >{mainNetwork.name}</Typography>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{color: "black"}}*/}
            {/*                            >{mainNetwork.caption}</Typography>*/}
            {/*                        </div>*/}
            {/*                    </Typography>*/}
            {/*                    <ExpandMore sx={{bgcolor: "primary1.main", color: "white", borderRadius: "50%"}}/>*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    ) : (<div>Please wait to load data</div>)}*/}
            {/*</Box>*/}
            {/*<Box sx={{*/}
            {/*    backgroundColor: 'primary3.main',*/}
            {/*    display: 'flex',*/}
            {/*    flexGrow: 1,*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    borderRadius: '1.25rem',*/}
            {/*    p: {xs: '24px 16px', sm: "24px 40px 28px"},*/}
            {/*    mt: {xs: "12px", md: "24px"}*/}
            {/*}}>*/}
            {/*    {stakePlanData.length !== 0 && <Grid container spacing={2}>*/}
            {/*        <Grid item xs={12} sx={{display: "flex", justifyContent: "start", alignItems: "end"}}>*/}
            {/*            <EmojiObjects sx={{color: "secondary5.main"}}/>*/}
            {/*            <Typography component="span"*/}
            {/*                        sx={{color: "white", fontSize: "13px", fontWeight: 600}}>Notes:</Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} sx={{pt: "0px !important"}}>*/}
            {/*            <Typography sx={{*/}
            {/*                pr: {xs: "16px", md: "32px"},*/}
            {/*                pl: {xs: "16px", md: "32px"},*/}
            {/*                fontSize: "13px",*/}
            {/*                color: "white"*/}
            {/*            }}><FiberManualRecord*/}
            {/*                sx={{fontSize: "6px", mr: 1}}/>Staking Fee is 0.00%, Gas fee 0.00 {mainCurrency.iso}*/}
            {/*            </Typography>*/}
            {/*            <Typography sx={{*/}
            {/*                pr: {xs: "16px", md: "32px"},*/}
            {/*                pl: {xs: "16px", md: "32px"},*/}
            {/*                fontSize: "13px",*/}
            {/*                color: "secondary7.main"*/}
            {/*            }}><FiberManualRecord*/}
            {/*                sx={{fontSize: "6px", mr: 1}}/>Minimum staking Amount*/}
            {/*                is {stakePlanData[stakePlanRadio].minAmount} {mainCurrency.iso}*/}
            {/*            </Typography>*/}
            {/*            /!*<Typography sx={{*!/*/}
            {/*            /!*    pr: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    pl: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    fontSize: "13px",*!/*/}
            {/*            /!*    color: "white"*!/*/}
            {/*            /!*}}><FiberManualRecord*!/*/}
            {/*            /!*    sx={{fontSize: "6px", mr: 1}}/>Maximum Crosschain Amount is 84,000,000 {mainCurrency.iso}*!/*/}
            {/*            /!*</Typography>*!/*/}
            {/*            <Typography sx={{*/}
            {/*                pr: {xs: "16px", md: "32px"},*/}
            {/*                pl: {xs: "16px", md: "32px"},*/}
            {/*                fontSize: "13px",*/}
            {/*                color: "secondary7.main"*/}
            {/*            }}><FiberManualRecord*/}
            {/*                sx={{fontSize: "6px", mr: 1}}/>Exact Time of staking arrival*/}
            {/*                is {stakePlanData[stakePlanRadio].releaseDuration}</Typography>*/}
            {/*            <Typography sx={{*/}
            {/*                pr: {xs: "16px", md: "32px"},*/}
            {/*                pl: {xs: "16px", md: "32px"},*/}
            {/*                fontSize: "13px",*/}
            {/*                color: "white"*/}
            {/*            }}><FiberManualRecord*/}
            {/*                sx={{fontSize: "6px", mr: 1}}/>Estimated Time of staking is less than 1 min</Typography>*/}
            {/*            <Typography sx={{*/}
            {/*                pr: {xs: "16px", md: "32px"},*/}
            {/*                pl: {xs: "16px", md: "32px"},*/}
            {/*                fontSize: "13px",*/}
            {/*                color: "secondary7.main"*/}
            {/*            }}><FiberManualRecord*/}
            {/*                sx={{fontSize: "6px", mr: 1}}/>Profit of staking*/}
            {/*                is {stakePlanData[stakePlanRadio].ratio}*/}
            {/*            </Typography>*/}
            {/*            /!*<Typography sx={{*!/*/}
            {/*            /!*    pr: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    pl: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    fontSize: "13px",*!/*/}
            {/*            /!*    color: "white"*!/*/}
            {/*            /!*}}><FiberManualRecord*!/*/}
            {/*            /!*    sx={{fontSize: "6px", mr: 1}}/>Crosschain amount larger than*!/*/}
            {/*            /!*    17,000,000 {mainCurrency.iso} could take up*!/*/}
            {/*            /!*    to 12 hours</Typography>*!/*/}
            {/*        </Grid>*/}
            {/*    </Grid>}*/}
            {/*</Box>*/}
            {/*<Box sx={{*/}
            {/*    display: 'flex',*/}
            {/*    flexGrow: 1,*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    mt: {xs: "12px", md: "24px"}*/}
            {/*}}>*/}
            {/*    <Button variant="contained" color="primary" onClick={onTransferBalancePressed} sx={{*/}
            {/*        width: 320,*/}
            {/*        height: 54,*/}
            {/*        borderRadius: 20,*/}
            {/*        fontWeight: "bold",*/}
            {/*        boxShadow: 0*/}
            {/*    }}>Stake</Button>*/}
            {/*</Box>*/}
            <Dialog
                open={openCurrencyModal}
                onClose={handleCurrencyModal}
                sx={{
                    "& .MuiPaper-root": {
                        width: 420,
                        maxWidth: 420,
                        bgcolor: 'grey8.main',
                        color: "white",
                        borderRadius: "20px",
                    }
                }}
            >
                <DialogTitle>
                    <Typography
                        component="div"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <div>Select a token</div>
                        <Clear sx={{fontSize: "24px", cursor: "pointer"}} onClick={handleCurrencyModal}/>
                    </Typography>
                    <TextField placeholder="Search name" onChange={handleFilterCurrency} sx={{
                        width: "100%",
                        pt: "12px",
                        "& .MuiInputBase-root": {
                            "& .MuiInputBase-input": {color: "white"},
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "20px",
                                borderColor: "white"
                            }
                        },
                        "& .Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "1px !important",
                                borderColor: "white !important"
                            },
                        }
                    }}/>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        borderColor: "white",
                        p: "0",
                        '&::-webkit-scrollbar': {display: 'none'}
                    }}
                >
                    <List sx={{pt: 0}}>
                        {currencyList.map((token, index) => (
                            <ListItem sx={{
                                justifyContent: "space-between",
                                pr: "24px",
                                pl: "24px",
                                "&:hover": {backgroundColor: "grey9.main"}
                            }} button onClick={() => handleCurrencyListItemClick(token)} key={index}>
                                <Typography component="div" sx={{display: "flex", alignItems: "center"}}>
                                    <Avatar
                                        alt=""
                                        src={token.icon}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            bgcolor: "white.main",
                                            marginRight: "15px"
                                        }}
                                    />
                                    <div>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            sx={{
                                                color: "white",
                                                fontSize: "1rem",
                                                fontWeight: 400
                                            }}
                                        >{token.iso}</Typography>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            sx={{color: "white"}}
                                        >{token.name}</Typography>
                                    </div>
                                </Typography>
                                <Typography component="div" sx={{color: "white"}}>{accountBalance}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openNetworkModal}
                onClose={() => handleNetworkModal()}
                sx={{
                    "& .MuiPaper-root": {
                        width: 420,
                        maxWidth: 420,
                        maxHeight: "80vh",
                        bgcolor: 'grey8.main',
                        color: "white",
                        borderRadius: "20px",
                    }
                }}
            >
                <DialogTitle>
                    <Typography
                        component="div"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <div>Switch to</div>
                        <Clear sx={{fontSize: "24px", cursor: "pointer"}} onClick={() => handleNetworkModal()}/>
                    </Typography>
                    <TextField
                        placeholder="Switch to"
                        onChange={handleFilterNetwork}
                        sx={{
                            width: "100%",
                            pt: "12px",
                            "& .MuiInputBase-root": {
                                "& .MuiInputBase-input": {color: "white"},
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "20px",
                                    borderColor: "white"
                                }
                            },
                            "& .Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "1px !important",
                                    borderColor: "white !important"
                                },
                            }
                        }}/>
                </DialogTitle>
                <DialogContent dividers sx={{
                    borderColor: "white",
                    p: "0",
                    '&::-webkit-scrollbar': {display: 'none'}
                }}>
                    <List sx={{pt: 0}}>
                        {networkList.map((token, index) => (
                            <ListItem sx={{
                                justifyContent: "space-between",
                                pr: "24px",
                                pl: "24px",
                                borderBottom: "1px solid",
                                borderBottomColor: "grey9.main",
                                "&:hover": {backgroundColor: "grey9.main"}
                            }} button onClick={() => handleNetworkListItemClick(token)} key={index}>
                                <Box sx={{display: "flex", alignItems: "center"}}>
                                    <Avatar
                                        alt=""
                                        src={token.icon}
                                        sx={{
                                            bgcolor: "white.main",
                                            marginRight: "15px"
                                        }}
                                    />
                                    <Box sx={{
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: 'primary6.main',
                                        mr: '6px'
                                    }}/>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        sx={{
                                            color: "white",
                                            fontSize: "1rem",
                                            fontWeight: 400
                                        }}
                                    >{token.name}</Typography>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        sx={{
                                            color: "grey5.main",
                                            fontSize: "0.8rem",
                                            fontWeight: 400,
                                            ml: 1
                                        }}
                                    >{token.caption}</Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openConfirmTransaction}
                sx={{
                    "& .MuiPaper-root": {
                        width: 420,
                        maxWidth: 420,
                        bgcolor: 'grey8.main',
                        color: "white",
                        borderRadius: "20px",
                    }
                }}
            >
                <DialogContent sx={{textAlign: "center"}}>
                    {!statusOfConfirmTransaction && <SyncSharpIcon sx={{
                        fontSize: "60px",
                        color: "info.main",
                        animation: "animate 3s infinite",
                        "@keyframes animate": {
                            from: {transform: "rotate(0deg)"},
                            to: {transform: "rotate(360deg)"}
                        }
                    }}/>}
                    {statusOfConfirmTransaction && <DoneAllSharpIcon sx={{
                        fontSize: "65px",
                        color: "primary6.main",
                    }}/>}
                    {!statusOfConfirmTransaction && <Typography
                        variant="caption"
                        component="p"
                        sx={{
                            mt: 3,
                            fontSize: "16px",
                            fontWeight: "600",
                            pb: "12px"
                        }}>Please wait for the transaction to be confirmed by the network ...</Typography>}
                    {statusOfConfirmTransaction && <Typography
                        variant="caption"
                        component="p"
                        sx={{
                            mt: 3,
                            fontSize: "16px",
                            fontWeight: "600",
                            pb: "12px"
                        }}>Your transaction confirmed successfully. Please continue the stake process from your
                        metamask
                        wallet.</Typography>}
                    {statusOfConfirmTransaction && <Button variant="text" onClick={handleConfirmTransaction} sx={{
                        bgcolor: "white.main",
                        color: "primary6.main",
                        width: 70,
                        height: 36,
                        borderRadius: 20,
                        mt: 3,
                        textTransform: "none",
                        fontWeight: "bold",
                        "&:hover": {
                            bgcolor: "grey9.main"
                        }
                    }}>Got It</Button>}
                </DialogContent>
            </Dialog>
            <Snackbar
                open={approveOpenToast}
                autoHideDuration={6000}
                key={approveStatus.status}
                onClose={handleCloseApproveToast}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Alert
                    onClose={handleCloseApproveToast}
                    severity={approveStatus.code === 200 ? "success" : "error"}
                    sx={{width: '100%'}}
                >{approveStatus.status}</Alert>
            </Snackbar>
            <Snackbar
                open={depositOpenToast}
                autoHideDuration={6000}
                key={stakeStatus.status}
                onClose={handleCloseDepositToast}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Alert
                    onClose={handleCloseDepositToast}
                    severity={stakeStatus.code === 200 ? "success" : "error"}
                    sx={{width: '100%'}}
                >{stakeStatus.status}</Alert>
            </Snackbar>
        </>
    );
};

export default Stake;