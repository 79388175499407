import {ethers} from "ethers";
import Web3 from "web3";
import NetworkType from "../models/network.model"
import InteractOptionType from "../models/interactOption.model";

declare var window: any;

const smartContractABI = JSON.parse(process.env.REACT_APP_SMART_CONTRACT_ABI!);

const bridgeContractABI = JSON.parse(process.env.REACT_APP_BRIDGE_CONTRACT_ABI!);

const stakeContractABI = JSON.parse(process.env.REACT_APP_STAKE_CONTRACT_ABI!);

const stake2ContractABI = JSON.parse(process.env.REACT_APP_STAKE2_CONTRACT_ABI!);

const airdropContractABI = JSON.parse(process.env.REACT_APP_AIRDROP_CONTRACT_ABI!);

const toHex = (covertThis: number, padding: any) => {
    return ethers.utils.hexZeroPad(ethers.utils.hexlify(covertThis), padding);
};

const createERCDepositData = (tokenAmountOrID: any, lenRecipientAddress: any, recipientAddress: any) => {
    return '0x' + toHex(tokenAmountOrID, 32).substr(2) + toHex(lenRecipientAddress, 32).substr(2) + recipientAddress.substr(2);
};

export const web3 = new Web3(window.ethereum);

export const smartContract = (smartContractAddress: string | any) => {
    return new web3.eth.Contract(
        smartContractABI,
        smartContractAddress
    );
}

export const bridgeContract = (bridgeContractAddress: string | any) => {
    return new web3.eth.Contract(
        bridgeContractABI,
        bridgeContractAddress
    );
}

export const stakeContract = (stakeContractAddress: string | any) => {
    return new web3.eth.Contract(
        stakeContractABI,
        stakeContractAddress
    );
}

export const stake2Contract = (stakeContractAddress: string | any) => {
    return new web3.eth.Contract(
        stake2ContractABI,
        stakeContractAddress
    );
}

export const airdropContract = (airdropContractAddress: string | any) => {
    return new web3.eth.Contract(
        airdropContractABI,
        airdropContractAddress
    );
}

export const connectWallet = () => {
    if (window.ethereum !== 'undefined') {
        try {
            const addressArray = window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "👆🏽 Write a message in the text-field above.",
                address: addressArray[0],
            };
            return obj;
        } catch (err: any) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "👆🏽 Populate the Data and Click on Button to execute...",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err: any) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const getAccountBalance = async (address: string) => {
    if (window.ethereum || address !== null) {
        try {
            const message = await web3.eth.getBalance(address);
            const balance = web3.utils.fromWei(String(message), "ether");
            if (balance) {
                return {
                    balance: balance,
                    status: "👆🏽 Populate the Data and Click on Button to execute...",
                };
            } else {
                return {
                    balance: 0,
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err: any) {
            return {
                balance: 0,
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            balance: 0,
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const switchChain = async (chain: NetworkType | any) => {
    if (window.ethereum) {
        try {
            const message = await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: chain.chainId}],
            });
            return {
                network: message,
                status: "👆🏽 Populate the Data and Click on Button to execute...",
            };
        } catch (err: any) {
            return {
                network: '',
                status: "😥 " + err.message,
                code: err.code
            };
        }
    } else {
        return {
            network: '',
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const setChainToAccount = async (chain: NetworkType | any) => {
    if (window.ethereum) {
        try {
            const network = window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: chain.chainId,
                    chainName: chain.networkName,
                    nativeCurrency: {
                        name: chain.nativeCurrency.name,
                        symbol: chain.nativeCurrency.symbol,
                        decimals: chain.nativeCurrency.decimals
                    },
                    rpcUrls: chain.rpcUrls,
                    blockExplorerUrls: chain.blockExplorerUrls
                }]
            })
            if (network) {
                return {
                    network: network,
                    status: "👆🏽 Populate the Data and Click on Button to execute...",
                };
            } else {
                return {
                    network: '',
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err: any) {
            return {
                network: '',
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            network: '',
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const getDefaultChain = async () => {
    if (window.ethereum) {
        try {
            const message = await web3.eth.getChainId();
            const chain = message !== 0 ? web3.utils.toHex(message) : '';
            if (chain) {
                return {
                    network: chain,
                    status: "👆🏽 Populate the Data and Click on Button to execute...",
                };
            } else {
                return {
                    network: '',
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err: any) {
            return {
                network: 0,
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            network: '',
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
}

export const getGasPrice = async () => {
    if (window.ethereum !== 'undefined') {
        try {
            const message = await web3.eth.getGasPrice();
            const gasPrice = web3.utils.fromWei(String(message), "ether");
            const obj = {
                status: "👆🏽 Write a message in the text-field above.",
                gas: gasPrice,
            };
            return obj;
        } catch (err: any) {
            return {
                status: "😥 " + err.message,
                gas: 0,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
}

export const getTokenBalance = async (accountAddress: string | any, smartContractAddress: string | any) => {
    if (!window.ethereum || accountAddress === null) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const contract = await smartContract(smartContractAddress);
    const message = await contract.methods.balanceOf(accountAddress).call();
    return web3.utils.fromWei(String(message), "ether");
};

export const approve = async (address: string | any, transferAddress: string | any, amount: string | any, smartContractAddress: string | any) => {
    if (!window.ethereum || address === null || transferAddress === null) {
        return {
            status: "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }

    const contract = await smartContract(smartContractAddress);
    const transactionParameters = {
        to: smartContractAddress,
        from: address,
        data: contract.methods.approve(transferAddress, web3.utils.toWei(amount, "ether")).encodeABI(),
    };

    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters]
        });
        if (txHash) {
            return {
                status: "Your transaction approved successfully.",
                hash: txHash,
                code: 200
            };
        }
    } catch (error: any) {
        return {
            status: "😥 " + error.message,
            code: 401
        };
    }
};

export const deposit = async (address: string | any, transferAddress: string | any, amount: string | any, network: string | any, bridgeContractAddress: string | any, blockExplorerUrls: string | any) => {
    if (!window.ethereum || address === null || transferAddress === null) {
        return {
            status: "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const contract = await bridgeContract(bridgeContractAddress);
    const destinationId = network === "Ethereum" ? 1 : network === 'Moonbeam' ? 2 : 3;
    const depositData = createERCDepositData(ethers.BigNumber.from(web3.utils.toWei(amount, "ether")), 20, transferAddress);
    const transactionParameters = {
        to: bridgeContractAddress,
        from: address,
        data: contract.methods.deposit(destinationId, toHex(0, 32), depositData).encodeABI()
    };

    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            status: (
                <span>
                    <a target="_blank" rel="noreferrer" href={`${blockExplorerUrls}/tx/${txHash}`}>View the status of your transaction!</a>
                </span>
            ),
            code: 200
        };
    } catch (error: any) {
        return {
            status: "😥 " + error.message,
            code: 401
        };
    }
};

export const stake = async (address: string | any, amount: string | any, stakeContractAddress: string | any, blockExplorerUrls: string | any) => {
    if (!window.ethereum || address === null) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const contract = await stakeContract(stakeContractAddress);
    const transactionParameters = {
        to: stakeContractAddress,
        from: address,
        data: contract.methods.stake(ethers.BigNumber.from(web3.utils.toWei(amount, "ether"))).encodeABI(),
    };

    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            status: (
                <span>
                    <a target="_blank" rel="noreferrer" href={`${blockExplorerUrls}/tx/${txHash}`}>View the status of your transaction!</a>
                </span>
            ),
            code: 200
        };
    } catch (error: any) {
        return {
            status: "😥 " + error.message,
            code: 401
        };
    }
};

export const stake2 = async (address: string | any, amount: string | any, stakeContractAddress: string | any, blockExplorerUrls: string | any, stakePlan: number | any) => {
    if (!window.ethereum || address === null) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const contract = await stake2Contract(stakeContractAddress);
    const transactionParameters = {
        to: stakeContractAddress,
        from: address,
        data: contract.methods.stake(ethers.BigNumber.from(web3.utils.toWei(amount, "ether")), stakePlan).encodeABI(),
    };

    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            status: (
                <span>
                    <a target="_blank" rel="noreferrer" href={`${blockExplorerUrls}/tx/${txHash}`}>View the status of your transaction!</a>
                </span>
            ),
            code: 200
        };
    } catch (error: any) {
        return {
            status: "😥 " + error.message,
            code: 401
        };
    }
};

export const stakeList = async (address: string | any, stakeContractAddress: string | any) => {
    if (!window.ethereum) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const contract = await stakeContract(stakeContractAddress);
    const data = await contract.methods.getStakeList().call({from: address});
    return data;
}

export const stake2List = async (address: string | any, stakeContractAddress: string | any) => {
    if (!window.ethereum) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const contract = await stake2Contract(stakeContractAddress);
    const data = await contract.methods.getStakeList().call({from: address});
    return data;
}

export const waitTransaction = async(txnHash: string, options: InteractOptionType) =>  {
    const interval = options && options.interval ? options.interval : 500;
    const blocksToWait = options && options.blocksToWait ? options.blocksToWait : 10;
    let transactionReceiptAsync = async function(txnHash: any, resolve: any, reject: any) {
        try {
            let receipt = web3.eth.getTransactionReceipt(txnHash);
            if (!receipt) {
                setTimeout(function () {
                    transactionReceiptAsync(txnHash, resolve, reject);
                }, interval);
            } else {
                if (blocksToWait > 0) {
                    let resolvedReceipt = await receipt;
                    if (!resolvedReceipt || !resolvedReceipt.blockNumber) setTimeout(function () { transactionReceiptAsync(txnHash, resolve, reject);
                    }, interval);
                    else {
                        try {
                            let block = await web3.eth.getBlock(resolvedReceipt.blockNumber)
                            let current = await web3.eth.getBlock('latest');
                            if (current.number - block.number >= blocksToWait) {
                                let txn = await web3.eth.getTransaction(txnHash)
                                if (txn.blockNumber != null) resolve(resolvedReceipt);
                                else reject(new Error('Transaction with hash: ' + txnHash + ' ended up in an uncle block.'));
                            }
                            else setTimeout(function () {
                                transactionReceiptAsync(txnHash, resolve, reject);
                            }, interval);
                        }
                        catch (e) {
                            setTimeout(function () {
                                transactionReceiptAsync(txnHash, resolve, reject);
                            }, interval);
                        }
                    }
                }
                else resolve(receipt);
            }
        } catch(e) {
            reject(e);
        }
    };
    return new Promise(function (resolve, reject) {
        transactionReceiptAsync(txnHash, resolve, reject);
    });
};

export const getPlans = async (stakeContractAddress: string | any) => {
    if (!window.ethereum) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const contract = await stake2Contract(stakeContractAddress);
    const data = await contract.methods.getPlans().call();
    return data;
}

export const airdropList = async (address: string | any, airdropContractAddress: string | any) => {
    if (!window.ethereum) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const contract = await airdropContract(airdropContractAddress);
    const data = await contract.methods.getAirdrop().call({from: address});
    return data;
}

