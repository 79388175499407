import React, {FC} from "react";
import {CssBaseline} from "@mui/material";
import LayoutProps from "../models/LayoutProps.model";

import Header from "../components/Header";
import Footer from "../components/Footer";
// import Sidebar from "../components/Sidebar";
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import MobileNavigation from "../components/MobileNavigation";
// import Navigation from "../components/Navigation";


const Layout: FC<LayoutProps> = ({children}) => {

    return <>
        <CssBaseline/>
        <Header/>
        <Container sx={{pl: 0, pr: 0}}>
            <Grid container sx={{minHeight: "100vh"}}>
                {/*<Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>*/}
                {/*    <Sidebar/>*/}
                {/*</Grid>*/}
                <Grid item xs={12} md={12} sx={{mt: {xs: 10, sm: 14, md: 15}, mb: {xs: "80px"}}}>
                    <main>{children}</main>
                </Grid>

            </Grid>
        </Container>
        <Grid item sx={{display: {xs: 'none', md: 'block'}}}>
            <Footer/>
        </Grid>
        <Grid item xs={12} md={3} sx={{display: {xs: 'flex', md: 'none'}}}>
            <MobileNavigation/>
        </Grid>
    </>
}

export default Layout;