import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import './index.css';
import App from './App';
import theme from './theme/theme';
import {store} from './store'
import {Provider} from 'react-redux'
import {WagmiConfig, createConfig , configureChains} from 'wagmi'
import {mainnet, optimism, polygon} from "wagmi/chains";
import {EthereumClient, w3mConnectors, w3mProvider,} from "@web3modal/ethereum";
if (!process.env.REACT_APP_CONNECT_WALLET_PROJECT_ID) {
  throw new Error("You need to provide REACT_APP_CONNECT_WALLET_PROJECT_ID env variable");
}
const projectId = process.env.REACT_APP_CONNECT_WALLET_PROJECT_ID;

const chains = [mainnet, polygon, optimism];
const {publicClient} = configureChains(chains, [w3mProvider({projectId})]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({chains, projectId}),
  publicClient,
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <WagmiConfig config={wagmiConfig}>
                <App/>
            </WagmiConfig>
        </ThemeProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
