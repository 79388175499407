import React, {ReactElement, FC} from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {styled} from '@mui/material/styles';
import dashboardType from "../../models/dashboard.model";
import {stakeList, stake2List} from "../../services/interact";
import stakeNetworkType from "../../models/stakeNetwork.model";
import bsc from "../../assets/images/bsc.png";
import {useAppSelector} from "../../store/type";
import dayjs from "dayjs";
import ether from "../../assets/images/etherium.png";
import Web3 from "web3";
declare var window: any;
export const web3 = new Web3(window.ethereum);

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.grey5.main,
        fontSize: 12,
        fontWeight: 400,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.grey4.main,
        border: 0
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.primary3.main,
        borderRadius: 50
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.primary4.main,
        borderRadius: 50
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const mainNetList: stakeNetworkType[] = [
    {
        name: "Ethereum",
        caption: "mainnet",
        icon: ether,
        chainId: "0x1",
        networkName: 'Ethereum Mainnet',
        nativeCurrency: {name: 'Ethereum', symbol: 'ETH', decimals: 18},
        rpcUrls: ['https://mainnet.infura.io/v3/'],
        blockExplorerUrls: ['https://etherscan.io'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_ETH,
        stakeContractAddress: process.env.REACT_APP_STAKE_ADDRESS_ETH,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_ETH
    },
    {
        name: "BSC",
        caption: "mainnet",
        icon: bsc,
        chainId: "0x38",
        networkName: 'Binance Smart Chain',
        nativeCurrency: {name: 'Binance Coin', symbol: 'BNB', decimals: 18},
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_BSC,
        stakeContractAddress: [process.env.REACT_APP_STAKE_ADDRESS_BSC, process.env.REACT_APP_STAKE_ADDRESS_BSC2],
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_BSC
    }
];

const Dashboard: FC<any> = (): ReactElement => {
        const address = useAppSelector((state) => state.wallet.address);
        const network = useAppSelector((state) => state.wallet.network);
        const [dataTable, setDataTable] = React.useState<dashboardType[] | any>([]);
        React.useEffect(() => {
            if (address && network && network !== "0x504") {
                const defaultNetwork = mainNetList.find(item => item.chainId === network)!;
                if (defaultNetwork) {
                    getStakeListDate(address, defaultNetwork.stakeContractAddress);
                }
            } else {
                setDataTable([]);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [address, network]);
        const thousandMask = (number: string) => {
            const spl = number && number.split(".");
            if (spl && spl[1]) {
                return numberWithCommas(spl[0]) + "." + spl[1];
            } else {
                return numberWithCommas(number);
            }
        };
        const numberWithCommas = (number: string) => {
            return number && number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };
        const getStakeListDate = async (address: string | any, stakeAddress: string | any) => {
            const tableData: dashboardType[] = [];
            if (typeof stakeAddress !== "string") {
                const data1 = await stakeList(address, stakeAddress[0]);
                const data2 = await stake2List(address, stakeAddress[1]);
                const sum = [...data1, ...data2];
                sum?.forEach((item: any, index: any) => {
                    if (Number(item[0]) !== 0) {
                        tableData.push({
                            id: index + 1,
                            asset: "HPO",
                            method: "Stake",
                            amount: thousandMask(web3.utils.fromWei(item[0], 'ether')),
                            releaseDate: dayjs(item[1] * 1000).format("MM/DD/YYYY, H:mm:ss")
                        })
                    }
                })
                setDataTable(tableData);
            } else {
                const data = await stakeList(address, stakeAddress);
                data?.forEach((item: any, index: any) => {
                    if (Number(item[0]) !== 0) {
                        tableData.push({
                            id: index,
                            asset: "HPO",
                            method: "Stake",
                            amount: thousandMask(web3.utils.fromWei(item[0], 'ether')),
                            releaseDate: dayjs(item[1] * 1000).format("MM/DD/YYYY, H:mm:ss")
                        })
                    }
                })
                setDataTable(tableData);
            }
        }
        return (
            <>
                <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: 0,
                        mt: "12px",
                        bgcolor: "transparent",
                        borderRadius: "20px"
                    }}
                >
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" sx={{bgcolor: "grey9.main"}}>ID</StyledTableCell>
                                <StyledTableCell align="center" sx={{bgcolor: "grey9.main"}}>Asset</StyledTableCell>
                                <StyledTableCell align="center" sx={{bgcolor: "grey9.main"}}>Method</StyledTableCell>
                                <StyledTableCell align="center" sx={{bgcolor: "grey9.main"}}>Amount</StyledTableCell>
                                <StyledTableCell align="center" sx={{bgcolor: "grey9.main"}}>Release
                                    Date</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataTable?.map((row: any, index: any) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell  sx={{bgcolor: "white.main"}} align="center">{row.id}</StyledTableCell>
                                    <StyledTableCell  sx={{bgcolor: "white.main"}} align="center">{row.asset}</StyledTableCell>
                                    <StyledTableCell  sx={{bgcolor: "white.main"}} align="center">{row.method}</StyledTableCell>
                                    <StyledTableCell  sx={{bgcolor: "white.main"}} align="center">{row.amount}</StyledTableCell>
                                    <StyledTableCell  sx={{bgcolor: "white.main"}} align="center">{row.releaseDate}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {dataTable.length === 0 && <Typography component="div" sx={{
                    width: "100%",
                    textAlign: "center",
                    mt: 2,
                    fontWeight: 700,
                    fontSize: 20,
                    color: "black",
                    height: "300px",
                    lineHeight: "300px"
                }}>No results found</Typography>}
            </>
        );
    }
;

export default Dashboard;