import * as React from 'react';
import logo from "../../assets/logo/logo-re.svg";
import {Instagram, LinkedIn, Telegram, Twitter} from '@mui/icons-material';
import {Avatar, Box, Container, Divider, ListItemAvatar, Typography} from '@mui/material';

function Copyright() {
    return (
        <Typography sx={{color: 'white.main', opacity: "0.2"}} align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            HPOtoken. All rights reserved
        </Typography>
    );
}

export default function Footer() {
    return (
        <Box sx={{
            backgroundImage:
                'url("/footer.svg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }} component="footer">
            <Container sx={{
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', py: 2}}>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            textDecoration: "none"
                        }}
                    >
                        <Avatar alt="" src={logo} sx={{
                            width: "24px",
                            height: "24px",
                            borderRadius: 0,
                            mr: 1
                        }}/> <Typography component="span" color="white"
                                         sx={{
                                             color: "white.main",
                                             fontSize: "18px",
                                             fontWeight: "bold",
                                             marginRight: "26px",
                                             textDecoration: "none",
                                             display: {xs: "none", md: "inline"}
                                         }}>HPOtoken</Typography>
                    </Typography>
                    <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                        <ListItemAvatar sx={{minWidth: "auto"}}>
                            <Avatar sx={{
                                backgroundColor: 'primary1.main',
                                "&:hover": {bgcolor: "primary4.main", "& .MuiSvgIcon-root": {color: "white"}}
                            }}>
                                <Telegram sx={{color: 'grey9.main'}}/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemAvatar sx={{minWidth: "auto"}}>
                            <Avatar sx={{
                                backgroundColor: 'primary1.main',
                                "&:hover": {bgcolor: "primary4.main", "& .MuiSvgIcon-root": {color: "white"}}
                            }}>
                                <Instagram sx={{color: 'grey9.main'}}/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemAvatar sx={{minWidth: "auto"}}>
                            <Avatar sx={{
                                backgroundColor: 'primary1.main',
                                "&:hover": {bgcolor: "primary4.main", "& .MuiSvgIcon-root": {color: "white"}}
                            }}>
                                <Twitter sx={{color: 'grey9.main'}}/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemAvatar sx={{minWidth: "auto"}}>
                            <Avatar sx={{
                                backgroundColor: 'primary1.main',
                                "&:hover": {bgcolor: "primary4.main", "& .MuiSvgIcon-root": {color: "white"}}
                            }}>
                                <LinkedIn sx={{color: 'grey9.main'}}/>
                            </Avatar>
                        </ListItemAvatar>
                    </Box>
                </Box>
                <Divider sx={{bgcolor: 'white.main', opacity: "0.2"}}/>
                <Box sx={{display: 'flex', py: 2}}>
                    <Copyright/>
                </Box>
            </Container>
        </Box>
    )

}