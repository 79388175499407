import React, {FC, ReactElement} from "react";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    List,
    ListItem,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import Switch, {SwitchProps} from '@mui/material/Switch';
import loading from "../../assets/images/loading.svg";

import {styled} from "@mui/material/styles";
import ether from "../../assets/images/etherium.png";
import hippo from "../../assets/images/hpo.png";
import {Clear, FiberManualRecord, InfoOutlined, KeyboardArrowDown} from '@mui/icons-material';
import bsc from "../../assets/images/bsc.png";
import moonbeam from "../../assets/images/moonbeam.png";
import NetworkType from "../../models/network.model"
import CurrencyType from "../../models/currency.model"
import {useAppDispatch, useAppSelector} from "../../store/type";
import {approve, deposit, getTokenBalance, switchChain, waitTransaction} from "../../services/interact";
import {changeNetwork} from "../../store/features/walletSlice";
import DoneAllSharpIcon from "@mui/icons-material/DoneAllSharp";

const Div = styled('div')(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    "@media(max-width: 600px)": {
        "&": {
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start"
        }
    }
}));

const mainNetList: NetworkType[] = [
    {
        name: "Ethereum",
        caption: "mainnet",
        icon: ether,
        chainId: "0x1",
        networkName: 'Ethereum Mainnet',
        nativeCurrency: {name: 'Ethereum', symbol: 'ETH', decimals: 18},
        rpcUrls: ['https://mainnet.infura.io/v3/'],
        blockExplorerUrls: ['https://etherscan.io'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_ETH,
        bridgeContractAddress: process.env.REACT_APP_BRIDGE_ADDRESS_ETH,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_ETH
    },
    {
        name: "BSC",
        caption: "mainnet",
        icon: bsc,
        chainId: "0x38",
        networkName: 'Binance Smart Chain',
        nativeCurrency: {name: 'Binance Coin', symbol: 'BNB', decimals: 18},
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_BSC,
        bridgeContractAddress: process.env.REACT_APP_BRIDGE_ADDRESS_BSC,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_BSC
    },
    {
        name: "Moonbeam",
        caption: "mainnet",
        icon: moonbeam,
        chainId: "0x504",
        networkName: 'Moonbeam',
        nativeCurrency: {name: 'Glimmer', symbol: 'GLMR', decimals: 18},
        rpcUrls: ['https://rpc.api.moonbeam.network'],
        blockExplorerUrls: ['https://moonscan.io'],
        smartContractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_MOONBEAM,
        bridgeContractAddress: process.env.REACT_APP_BRIDGE_ADDRESS_MOONBEAM,
        handlerContractAddress: process.env.REACT_APP_HANDLER_ADDRESS_MOONBEAM
    }
];

const coinList: CurrencyType[] = [
    {iso: "HPO", name: "Hippopotamus", icon: hippo}
]

const Bridge: FC<any> = (): ReactElement => {
    const address = useAppSelector((state) => state.wallet.address);
    const network = useAppSelector((state) => state.wallet.network);
    const gasPrice = useAppSelector((state) => state.wallet.gas);
    const balance = useAppSelector((state) => state.wallet.balance);
    const dispatch = useAppDispatch();
    const [approveOpenToast, setApproveOpenToast] = React.useState(false);
    const [depositOpenToast, setDepositOpenToast] = React.useState(false);
    const [fromAmount, setFromAmount] = React.useState('');
    const [toAmount, setToAmount] = React.useState('');
    const [toAddress, setToAddress] = React.useState('');
    const [accountBalance, setAccountBalance] = React.useState('');
    const [walletType, setWalletType] = React.useState<boolean>(false);
    const [networkList, setNetworkList] = React.useState<NetworkType[]>([]);
    const [toNetworkList, setToNetworkList] = React.useState<NetworkType[]>([]);
    const [currencyList, setCurrencyList] = React.useState<CurrencyType[]>([]);
    const [mainNetwork, setMainNetwork] = React.useState<NetworkType | any>({});
    const [toMainNetwork, setToMainNetwork] = React.useState<NetworkType | any>({});
    const [mainCurrency, setMainCurrency] = React.useState<CurrencyType>({});
    const [openCurrencyModal, setOpenCurrencyModal] = React.useState(false);
    const [openNetworkModal, setOpenNetworkModal] = React.useState(false);
    const [networkDirection, setNetworkDirection] = React.useState('');
    const [approveStatus, setApproveStatus] = React.useState<object | any>({status: "approve", code: 0});
    const [depositStatus, setDepositStatus] = React.useState<object | any>({status: "deposit", code: 0});
    const [fromAmountErrorState, setFromAmountErrorState] = React.useState({errorStatus: false, errorComment: ''});
    const [openConfirmTransaction, setOpenConfirmTransaction] = React.useState(false);
    const [statusOfConfirmTransaction, setStatusOfConfirmTransaction] = React.useState(false);
    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch
            checked={walletType}
            onChange={(event) => handleWalletAddress(event.target.checked)} focusVisibleClassName=".Mui-focusVisible"
            disableRipple {...props} />
    ))(({theme}) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#EDCB50',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#EDCB50',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    React.useEffect(() => {
        setNetworkList(mainNetList);
        setMainNetwork(mainNetList[0]);
        const defaultNetwork = mainNetList[0];
        if (defaultNetwork.name === "Ethereum") {
            let data: NetworkType[] = [];
            mainNetList.forEach(item => {
                if (item.name !== defaultNetwork.name) {
                    data.push(item);
                    setToNetworkList(data);
                    setToMainNetwork(data[0]);
                }
            })
        } else {
            mainNetList.forEach(item => {
                let data: NetworkType[] = [];
                if (item.name === 'Ethereum') {
                    data.push(item);
                    setToNetworkList(data);
                    setToMainNetwork(item);
                }
            })
        }
        setCurrencyList(coinList);
        setMainCurrency(coinList[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (network) {
            const defaultNetwork = mainNetList.find(item => item.chainId === network)!;
            if (defaultNetwork) {
                setMainNetwork(defaultNetwork);
                if (defaultNetwork.name === "Ethereum") {
                    let data: NetworkType[] = [];
                    mainNetList.forEach(item => {
                        if (item.name !== defaultNetwork.name) {
                            data.push(item);
                            setToNetworkList(data);
                            setToMainNetwork(data[0]);
                        }
                    })
                } else {
                    mainNetList.forEach(item => {
                        let data: NetworkType[] = [];
                        if (item.name === 'Ethereum') {
                            data.push(item);
                            setToNetworkList(data);
                            setToMainNetwork(item);
                        }
                    })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network]);
    React.useEffect(() => {
        if (network) {
            const defaultNetwork = mainNetList.find(item => item.chainId === network)!;
            if (defaultNetwork) {
                setMainNetwork(defaultNetwork);
                getAccountBalance(address, defaultNetwork.smartContractAddress! as string);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network, address])
    const getAccountBalance = async (accountAddress: string, smartContractAddress: string) => {
        if (accountAddress && smartContractAddress) {
            const amount = await getTokenBalance(accountAddress, smartContractAddress);
            const strAmount = String(Number(amount).toFixed(3));
            setAccountBalance(strAmount);
        }
    }
    const removeThousandMask = (number: string) => {
        return number.replace(/,/gi, "");
    }
    const thousandMask = (number: string) => {
        const spl = number && number.split(".");
        if (spl && spl[1]) {
            return numberWithCommas(spl[0]) + "." + spl[1];
        } else {
            return numberWithCommas(number);
        }
    };
    const numberWithCommas = (number: string) => {
        return number && number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dataWithoutThousand = removeThousandMask(event.target.value);
        const dataWithThousand = thousandMask(dataWithoutThousand);
        setFromAmount(dataWithThousand);
        setToAmount(dataWithThousand);
        if (!event.target.value) {
            setFromAmountErrorState({errorStatus: true, errorComment: 'Please enter from amount.'});
        } else if (Number(removeThousandMask(event.target.value)) > Number(accountBalance)) {
            setFromAmountErrorState({errorStatus: true, errorComment: 'Your balance is not enough.'});
        } else if (Number(balance) < Number(gasPrice)) {
            setFromAmountErrorState({errorStatus: true, errorComment: 'Your main balance is not enough.'});
        } else {
            setFromAmountErrorState({errorStatus: false, errorComment: ''});
        }
    };
    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToAddress(event.target.value);
    };
    const handleWalletAddress = (event: any) => {
        setWalletType(event)
        console.log(walletType)
    };
    const handleCurrencyModal = () => {
        setOpenCurrencyModal(!openCurrencyModal)
    };
    const handleNetworkModal = (type: string) => {
        setOpenNetworkModal(!openNetworkModal);
        setNetworkDirection(type);
    };
    const handleCurrencyListItemClick = (token: object) => {
        setMainCurrency(token);
        handleCurrencyModal();
    };
    const handleNetworkListItemClick = async (token: object) => {
        if (networkDirection === "fromNetwork") {
            const data = await switchChain(token);
            if (data.code === 4902) {
                dispatch(changeNetwork(token))
            }
        } else if (networkDirection === "toNetwork") {
            setToMainNetwork(token);
        }
        handleNetworkModal(networkDirection);
    };
    const handleFilterCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== '') {
            const filteredData = coinList.filter((item: object) => {
                return Object.values(item).join('').toLowerCase().includes(event.target.value.toLowerCase())
            })
            setCurrencyList(filteredData);
        } else {
            setCurrencyList(coinList);
        }
    }
    const handleFilterNetwork = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== '') {
            const filteredNetworkData = mainNetList.filter((item: object) => {
                return Object.values(item).join('').toLowerCase().includes(event.target.value.toLowerCase())
            })
            setNetworkList(filteredNetworkData);
        } else {
            setNetworkList(mainNetList);
        }
    }
    const onTransferBalancePressed = async () => {
        if (!fromAmountErrorState.errorStatus) {
            const amount = removeThousandMask(toAmount);
            const desAddress = toAddress === '' ? address : toAddress;
            const approveState = await approve(address, mainNetwork.handlerContractAddress, amount, mainNetwork.smartContractAddress);
            setApproveStatus(approveState);
            setApproveOpenToast(!approveOpenToast);
            if (approveState?.code === 200) {
                setOpenConfirmTransaction(true);
                const receiptTransaction: any = await waitTransaction(approveState?.hash, {
                    interval: 1000,
                    blocksToWait: 2
                });
                if (receiptTransaction?.status === true) {
                    setStatusOfConfirmTransaction(true);
                    const depositState = await deposit(address, desAddress, amount, toMainNetwork.name, mainNetwork.bridgeContractAddress, mainNetwork.blockExplorerUrls[0]);
                    setDepositStatus(depositState);
                    setDepositOpenToast(!depositOpenToast);
                    setOpenConfirmTransaction(false);
                    setStatusOfConfirmTransaction(false);
                }
            }
        }
    }
    const handleCloseApproveToast = async () => {
        setApproveOpenToast(false);
    }
    const handleCloseDepositToast = async () => {
        setDepositOpenToast(false);
    }
    const handleConfirmTransaction = () => {
        setOpenConfirmTransaction(false);
    }
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '1.25rem',
                p: {xs: '24px 16px', sm: "24px 40px 28px"},
            }}>
                <Box sx={{
                    minWidth: {xs: '100%', md: '395px'},
                    maxWidth: {xs: '100%', md: '395px'},
                    backgroundColor: "white.main",
                    p: {xs: 0, md: 2},
                    borderRadius: "16px"
                }}>
                    <Typography component="h5" sx={{
                        mb: 1,
                        color: "black",
                        fontSize: "12px"
                    }}>From Network</Typography>
                    <FormControl sx={{mb: 1, width: '100%'}}>
                        <Button variant="contained"
                                color={'grey8'}
                                sx={{
                                    height: "56px",
                                    borderRadius: '16px',
                                    display: "flex", justifyContent: 'space-between'
                                }}
                                disableElevation
                                endIcon={<KeyboardArrowDown sx={{
                                    color: "primary5.main",
                                }}/>}
                                onClick={() => handleNetworkModal("fromNetwork")}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexGrow: 1,
                                alignItems: 'center',
                            }}>
                                <Avatar
                                    alt=""
                                    src={mainNetwork.icon}
                                    sx={{
                                        mr: 0.5,
                                        width: "20px",
                                        height: "20px",
                                    }}
                                />
                                {mainNetwork.name}
                            </Box>
                        </Button>
                    </FormControl>
                    <Typography component="h5" sx={{
                        mb: 1,
                        color: "black",
                        fontSize: "12px"
                    }}>To Network</Typography>
                    <FormControl sx={{mb: 1, width: '100%'}}>
                        <Button variant="contained"
                                color={'grey8'}
                                sx={{
                                    height: "56px",
                                    borderRadius: '16px',
                                    display: "flex", justifyContent: 'space-between',
                                }}
                                disableElevation
                                endIcon={<KeyboardArrowDown sx={{
                                    color: "primary5.main",
                                }}/>}
                                onClick={() => handleNetworkModal("toNetwork")}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexGrow: 1,
                                alignItems: 'center',
                            }}>
                                <Avatar
                                    alt=""
                                    src={toMainNetwork.icon}
                                    sx={{
                                        mr: 0.5,
                                        width: "20px",
                                        height: "20px",
                                    }}
                                />
                                {toMainNetwork.networkName}
                            </Box>
                        </Button>
                    </FormControl>
                    <Typography component="h5" sx={{
                        mb: 1,
                        color: "black",
                        fontSize: "12px"
                    }}>Amount</Typography>
                    <Box sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            backgroundColor: "#F6F6F7",
                            height: '56px',
                            px: 2,
                            borderRadius: '16px 0 0 16px',
                            mb: fromAmountErrorState.errorComment ? 3 : 0
                        }}>
                            <Avatar
                                alt=""
                                src={mainCurrency.icon}
                                sx={{
                                    mr: 0.5,
                                    width: "20px",
                                    height: "20px",
                                }}
                            />
                            {mainCurrency.iso}
                        </Box>
                        <TextField onChange={handleChange} value={fromAmount}
                                   helperText={fromAmountErrorState.errorComment}
                                   error={fromAmountErrorState.errorStatus} placeholder="0.000" fullWidth
                                   variant="outlined" sx={{
                            "& .MuiInputBase-root": {
                                direction: "rtl",
                                borderColor: "white",

                                "& .MuiInputBase-input": {borderColor: "white !important"},
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: '0 16px 16px 0',
                                    borderColor: "#F6F6F7"
                                }
                            },
                            ".MuiFormHelperText-root": {
                                textAlign: 'end',
                            }
                        }}/>
                    </Box>
                    {coinList.length !== 0 && networkList.length !== 0 ? (
                        <Typography component="h5" sx={{
                            mb: 1,
                            textAlign: 'end',
                            color: "primary5.main",
                            fontSize: "12px"
                        }}> Total
                            Balance: {balance} {mainNetwork.nativeCurrency.symbol} / {accountBalance} {mainCurrency.iso}
                        </Typography>
                    ) : ''}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <Typography component="span"
                                    sx={{
                                        color: "grey1.main",
                                        fontSize: "13px",
                                        fontWeight: 600
                                    }}>To another address:</Typography>
                        <IOSSwitch sx={{m: 1}}/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2
                    }}>
                        <Typography component="span"
                                    sx={{
                                        color: "grey6.main",
                                        fontSize: "13px",
                                        fontWeight: 600
                                    }}>You can send bridge amount to another address by Turning on “To another
                            address”.</Typography>
                    </Box>

                    {walletType ? <Box>
                            <Typography component="h5" sx={{
                                mb: 1,
                                color: "black",
                                fontSize: "12px"
                            }}>Wallet address</Typography>
                            <TextField onChange={handleChangeAddress} value={toAddress}
                                       placeholder="Wallet address"
                                       fullWidth
                                       variant="outlined" sx={{
                                "& .MuiInputBase-root": {
                                    borderColor: "white",

                                    "& .MuiInputBase-input": {borderColor: "white !important"},
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderRadius: '16px',
                                        borderColor: "#F6F6F7"
                                    }
                                },
                            }}/> </Box>

                        : ''}
                    <Box sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '1.25rem',
                        border: '1px solid #347362',
                        p: 2.5,
                        mt: 1,
                        mb: 1
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                <InfoOutlined sx={{color: "primary5.main"}}/>
                                <Typography component="span"
                                            sx={{
                                                color: "primary5.main",
                                                fontSize: "13px",
                                                fontWeight: 600,
                                                ml:1
                                            }}>Notes:</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{pt: "0px !important"}}>
                                <Typography sx={{
                                    pr: {xs: "16px", md: "0px"},
                                    pl: {xs: "16px", md: "32px"},
                                    fontSize: "13px",
                                    color: "primary5.main"
                                }}><FiberManualRecord
                                    sx={{fontSize: "6px", mr: 1}}/>Crosschain fee is 0.00% + Network gas fee
                                </Typography>
                                {/*<Typography sx={{*/}
                                {/*    pr: {xs: "16px", md: "32px"},*/}
                                {/*    pl: {xs: "16px", md: "32px"},*/}
                                {/*    fontSize: "13px",*/}
                                {/*    color: "white"*/}
                                {/*}}><FiberManualRecord*/}
                                {/*    sx={{fontSize: "6px", mr: 1}}/>Minimum Crosschain Amount is 750 {mainCurrency.iso}*/}
                                {/*</Typography>*/}
                                {/*<Typography sx={{*/}
                                {/*    pr: {xs: "16px", md: "32px"},*/}
                                {/*    pl: {xs: "16px", md: "32px"},*/}
                                {/*    fontSize: "13px",*/}
                                {/*    color: "white"*/}
                                {/*}}><FiberManualRecord*/}
                                {/*    sx={{fontSize: "6px", mr: 1}}/>Maximum Crosschain Amount is 84,000,000 {mainCurrency.iso}*/}
                                {/*</Typography>*/}
                                <Typography sx={{
                                    pr: {xs: "16px", md: "0px"},
                                    pl: {xs: "16px", md: "32px"},
                                    fontSize: "13px",
                                    color: "primary5.main"
                                }}><FiberManualRecord
                                    sx={{fontSize: "6px", mr: 1}}/>Estimated time of crosschain arrival is 1-3
                                    min</Typography>
                                {/*<Typography sx={{*/}
                                {/*    pr: {xs: "16px", md: "32px"},*/}
                                {/*    pl: {xs: "16px", md: "32px"},*/}
                                {/*    fontSize: "13px",*/}
                                {/*    color: "white"*/}
                                {/*}}><FiberManualRecord*/}
                                {/*    sx={{fontSize: "6px", mr: 1}}/>Crosschain amount larger than*/}
                                {/*    17,000,000 {mainCurrency.iso} could take up*/}
                                {/*    to 12 hours</Typography>*/}
                            </Grid>
                        </Grid>
                    </Box>
                    <Button fullWidth variant="contained" color="primary5" onClick={onTransferBalancePressed} sx={{
                        height: 54,
                        borderRadius: "16px",
                        fontWeight: "bold",
                        color: "white.main",
                        boxShadow: 0
                    }}>Bridge</Button>
                </Box>
            </Box>
            {/*<Div>*/}
            {/*    <Typography variant="h5" component="h5"*/}
            {/*                sx={{mb: {xs: "12px", md: "24px"}, color: "black.main"}}>Bridge</Typography>*/}
            {/*    <ButtonGroup*/}
            {/*        variant="contained"*/}
            {/*        sx={{*/}
            {/*            boxShadow: 0,*/}
            {/*            mb: {xs: "12px", md: "24px"},*/}
            {/*            "& .MuiButtonBase-root": {borderRadius: "8px"}*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Button color={walletType === 'current wallet' ? "primary3" : "grey7"}*/}
            {/*                onClick={() => handleWalletAddress('current wallet')} sx={{textTransform: "none"}}>*/}
            {/*            <Avatar*/}
            {/*                sx={{*/}
            {/*                    width: {xs: 20, sm: 25, md: 30},*/}
            {/*                    height: {xs: 20, sm: 25, md: 30},*/}
            {/*                    bgColor: "white.main",*/}
            {/*                    marginRight: "4px",*/}
            {/*                    color: "black.main",*/}
            {/*                    fontSize: {xs: "8px", md: "10px"},*/}
            {/*                    fontWeight: 600*/}
            {/*                }}*/}
            {/*            >CW</Avatar>*/}
            {/*            Current Wallet*/}
            {/*        </Button>*/}
            {/*        <Button color={walletType === 'new wallet' ? "primary3" : "grey7"}*/}
            {/*                onClick={() => handleWalletAddress('new wallet')} sx={{textTransform: "none"}}>*/}
            {/*            <Avatar*/}
            {/*                sx={{*/}
            {/*                    width: {xs: 20, sm: 25, md: 30},*/}
            {/*                    height: {xs: 20, sm: 25, md: 30},*/}
            {/*                    bgColor: "white.main",*/}
            {/*                    marginRight: "4px",*/}
            {/*                    color: "black.main",*/}
            {/*                    fontSize: {xs: "8px", md: "10px"},*/}
            {/*                    fontWeight: 600*/}
            {/*                }}*/}
            {/*            >RW</Avatar>*/}
            {/*            Receiver Wallet*/}
            {/*        </Button>*/}
            {/*    </ButtonGroup>*/}
            {/*</Div>*/}
            {/*<Box sx={{*/}
            {/*    backgroundColor: 'grey8.main',*/}
            {/*    display: 'flex',*/}
            {/*    flexGrow: 1,*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    borderRadius: '1.25rem',*/}
            {/*    p: {xs: '24px 16px', sm: "24px 40px 28px"},*/}
            {/*}}>*/}
            {/*    {coinList.length !== 0 && networkList.length !== 0 ? (*/}
            {/*        <Grid container spacing={2}>*/}
            {/*            <Grid item xs={12} sx={{*/}
            {/*                display: "flex",*/}
            {/*                justifyContent: "space-between",*/}
            {/*                color: "black.main"*/}
            {/*            }}>*/}
            {/*                <div>From</div>*/}
            {/*                <div>Balance: {balance} {mainNetwork.nativeCurrency.symbol} / {accountBalance} {mainCurrency.iso}</div>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} md={6}>*/}
            {/*                <TextField*/}
            {/*                    error={fromAmountErrorState.errorStatus}*/}
            {/*                    helperText={fromAmountErrorState.errorComment}*/}
            {/*                    placeholder="0.0"*/}
            {/*                    variant="standard"*/}
            {/*                    value={fromAmount}*/}
            {/*                    onChange={handleChange}*/}
            {/*                    InputProps={{*/}
            {/*                        sx: {*/}
            {/*                            height: {xs: 44, sm: 57, md: 70},*/}
            {/*                            fontSize: "20px",*/}
            {/*                            color: "white",*/}
            {/*                            "&:after": {*/}
            {/*                                borderColor: "grey8.main",*/}
            {/*                            },*/}
            {/*                            "&:before": {*/}
            {/*                                borderColor: "grey8.main",*/}
            {/*                            }*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    sx={{*/}
            {/*                        width: "100%",*/}
            {/*                        "& .MuiInputBase-input": {*/}
            {/*                            fontSize: {xs: "30px", sm: "38px", md: "44px"}*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} sm={6} md={3} onClick={handleCurrencyModal}>*/}
            {/*                <Box sx={{*/}
            {/*                    backgroundColor: 'grey8.main',*/}
            {/*                    border: 1,*/}
            {/*                    borderColor: "primary6.main",*/}
            {/*                    display: 'flex',*/}
            {/*                    justifyContent: 'space-between',*/}
            {/*                    alignItems: 'center',*/}
            {/*                    borderRadius: '1.25rem',*/}
            {/*                    p: '12px',*/}
            {/*                    width: "100%",*/}
            {/*                    height: "100%",*/}
            {/*                    "&:hover": {*/}
            {/*                        bgcolor: "primary6.main",*/}
            {/*                        cursor: "pointer"*/}
            {/*                    }*/}
            {/*                }}>*/}
            {/*                    <Typography component="div" sx={{display: "flex", alignItems: "center"}}>*/}
            {/*                        <Avatar*/}
            {/*                            src={mainCurrency.icon}*/}
            {/*                            sx={{*/}
            {/*                                width: {xs: 30, sm: 38, md: 46},*/}
            {/*                                height: {xs: 30, sm: 38, md: 46},*/}
            {/*                                bgcolor: "white",*/}
            {/*                                marginRight: "4px",*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                        <div>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{*/}
            {/*                                    color: "white",*/}
            {/*                                    fontSize: "1rem",*/}
            {/*                                    fontWeight: 800*/}
            {/*                                }}*/}
            {/*                            >{mainCurrency.iso}</Typography>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{color: "white"}}*/}
            {/*                            >{mainCurrency.name}</Typography>*/}
            {/*                        </div>*/}
            {/*                    </Typography>*/}
            {/*                    <ExpandMore sx={{bgcolor: "primary6.main", color: "white.main", borderRadius: "50%"}}/>*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} sm={6} md={3} onClick={() => handleNetworkModal("fromNetwork")}>*/}
            {/*                <Box sx={{*/}
            {/*                    backgroundColor: 'grey8.main',*/}
            {/*                    border: 1,*/}
            {/*                    borderColor: "primary6.main",*/}
            {/*                    display: 'flex',*/}
            {/*                    justifyContent: 'space-between',*/}
            {/*                    alignItems: 'center',*/}
            {/*                    borderRadius: '1.25rem',*/}
            {/*                    p: '12px',*/}
            {/*                    width: "100%",*/}
            {/*                    height: "100%",*/}
            {/*                    "&:hover": {*/}
            {/*                        bgcolor: "primary6.main",*/}
            {/*                        cursor: "pointer"*/}
            {/*                    }*/}
            {/*                }}>*/}
            {/*                    <Typography component="div" sx={{display: "flex", alignItems: "center"}}>*/}
            {/*                        <Avatar*/}
            {/*                            alt=""*/}
            {/*                            src={mainNetwork.icon}*/}
            {/*                            sx={{*/}
            {/*                                width: {xs: 30, sm: 38, md: 46},*/}
            {/*                                height: {xs: 30, sm: 38, md: 46},*/}
            {/*                                bgcolor: "white",*/}
            {/*                                marginRight: "4px"*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                        <div>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{*/}
            {/*                                    color: "black",*/}
            {/*                                    fontSize: "1rem",*/}
            {/*                                    fontWeight: 800*/}
            {/*                                }}*/}
            {/*                            >{mainNetwork.name}</Typography>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{color: "black"}}*/}
            {/*                            >{mainNetwork.caption}</Typography>*/}
            {/*                        </div>*/}
            {/*                    </Typography>*/}
            {/*                    <ExpandMore sx={{bgcolor: "primary6.main", color: "white.main", borderRadius: "50%"}}/>*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    ) : (<div>Please wait to load data</div>)}*/}
            {/*</Box>*/}
            {/*<Box sx={{*/}
            {/*    backgroundColor: 'grey8.main',*/}
            {/*    display: 'flex',*/}
            {/*    flexGrow: 1,*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    borderRadius: '1.25rem',*/}
            {/*    p: {xs: '24px 16px', sm: "24px 40px 28px"},*/}
            {/*    mt: {xs: "12px", md: "24px"}*/}
            {/*}}>*/}
            {/*    {coinList.length !== 0 && networkList.length !== 0 ? (*/}
            {/*        <Grid container spacing={2}>*/}
            {/*            <Grid item xs={12} sx={{*/}
            {/*                display: "flex",*/}
            {/*                justifyContent: "space-between",*/}
            {/*                color: "black.main"*/}
            {/*            }}>*/}
            {/*                <div>To</div>*/}
            {/*                <div>Balance: 0.000</div>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} md={6}>*/}
            {/*                <TextField*/}
            {/*                    placeholder="0.0"*/}
            {/*                    variant="standard"*/}
            {/*                    value={toAmount}*/}
            {/*                    onChange={handleChange}*/}
            {/*                    disabled*/}
            {/*                    InputProps={{*/}
            {/*                        sx: {*/}
            {/*                            height: {xs: 44, sm: 57, md: 70},*/}
            {/*                            fontSize: "20px",*/}
            {/*                            color: "white",*/}
            {/*                            "&:after": {*/}
            {/*                                borderColor: "grey8.main",*/}
            {/*                            },*/}
            {/*                            "&:before": {*/}
            {/*                                borderColor: "grey8.main",*/}
            {/*                            }*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    sx={{*/}
            {/*                        width: "100%",*/}
            {/*                        "& .MuiInputBase-input": {*/}
            {/*                            fontSize: {xs: "30px", sm: "38px", md: "44px"}*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} sm={6} md={3} onClick={handleCurrencyModal}>*/}
            {/*                <Box sx={{*/}
            {/*                    backgroundColor: 'grey8.main',*/}
            {/*                    border: 1,*/}
            {/*                    borderColor: "primary6.main",*/}
            {/*                    display: 'flex',*/}
            {/*                    justifyContent: 'space-between',*/}
            {/*                    alignItems: 'center',*/}
            {/*                    borderRadius: '1.25rem',*/}
            {/*                    p: '12px',*/}
            {/*                    width: "100%",*/}
            {/*                    height: "100%",*/}
            {/*                    "&:hover": {*/}
            {/*                        bgcolor: "primary6.main",*/}
            {/*                        cursor: "pointer"*/}
            {/*                    }*/}
            {/*                }}>*/}
            {/*                    <Typography component="div" sx={{display: "flex", alignItems: "center"}}>*/}
            {/*                        <Avatar*/}
            {/*                            alt=""*/}
            {/*                            src={mainCurrency.icon}*/}
            {/*                            sx={{*/}
            {/*                                width: {xs: 30, sm: 38, md: 46},*/}
            {/*                                height: {xs: 30, sm: 38, md: 46},*/}
            {/*                                bgcolor: "white",*/}
            {/*                                marginRight: "4px"*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                        <div>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{*/}
            {/*                                    color: "white",*/}
            {/*                                    fontSize: "1rem",*/}
            {/*                                    fontWeight: 800*/}
            {/*                                }}*/}
            {/*                            >{mainCurrency.iso}</Typography>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{color: "white"}}*/}
            {/*                            >{mainCurrency.name}</Typography>*/}
            {/*                        </div>*/}
            {/*                    </Typography>*/}
            {/*                    <ExpandMore sx={{bgcolor: "primary6.main", color: "white.main", borderRadius: "50%"}}/>*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} sm={6} md={3} onClick={() => handleNetworkModal("toNetwork")}>*/}
            {/*                <Box sx={{*/}
            {/*                    backgroundColor: 'grey8.main',*/}
            {/*                    border: 1,*/}
            {/*                    borderColor: "primary6.main",*/}
            {/*                    display: 'flex',*/}
            {/*                    justifyContent: 'space-between',*/}
            {/*                    alignItems: 'center',*/}
            {/*                    borderRadius: '1.25rem',*/}
            {/*                    p: '12px',*/}
            {/*                    width: "100%",*/}
            {/*                    height: "100%",*/}
            {/*                    "&:hover": {*/}
            {/*                        bgcolor: "primary6.main",*/}
            {/*                        cursor: "pointer"*/}
            {/*                    }*/}
            {/*                }}>*/}
            {/*                    <Typography component="div" sx={{display: "flex", alignItems: "center"}}>*/}
            {/*                        <Avatar*/}
            {/*                            alt=""*/}
            {/*                            src={toMainNetwork.icon}*/}
            {/*                            sx={{*/}
            {/*                                width: {xs: 30, sm: 38, md: 46},*/}
            {/*                                height: {xs: 30, sm: 38, md: 46},*/}
            {/*                                bgcolor: "white",*/}
            {/*                                marginRight: "4px"*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                        <div>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{*/}
            {/*                                    color: "black",*/}
            {/*                                    fontSize: "1rem",*/}
            {/*                                    fontWeight: 800*/}
            {/*                                }}*/}
            {/*                            >{toMainNetwork.name}</Typography>*/}
            {/*                            <Typography*/}
            {/*                                variant="caption"*/}
            {/*                                component="div"*/}
            {/*                                sx={{color: "black"}}*/}
            {/*                            >{toMainNetwork.caption}</Typography>*/}
            {/*                        </div>*/}
            {/*                    </Typography>*/}
            {/*                    <ExpandMore sx={{bgcolor: "primary6.main", color: "white.main", borderRadius: "50%"}}/>*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    ) : (<div>Please wait to load data</div>)}*/}
            {/*</Box>*/}
            {/*{walletType === 'new wallet' ? (*/}
            {/*    <Box sx={{*/}
            {/*        backgroundColor: 'primary.main',*/}
            {/*        display: 'flex',*/}
            {/*        flexGrow: 1,*/}
            {/*        justifyContent: 'center',*/}
            {/*        alignItems: 'center',*/}
            {/*        borderRadius: '1.25rem',*/}
            {/*        p: {xs: '24px 16px', sm: "24px 40px 28px"},*/}
            {/*        mt: {xs: "12px", md: "24px"}*/}
            {/*    }}>*/}
            {/*        <Grid container spacing={2}>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <Typography component="div" sx={{color: "white"}}>Recipient</Typography>*/}
            {/*                <TextField*/}
            {/*                    placeholder="address"*/}
            {/*                    variant="standard"*/}
            {/*                    value={toAddress}*/}
            {/*                    onChange={handleChangeAddress}*/}
            {/*                    InputProps={{*/}
            {/*                        sx: {*/}
            {/*                            height: {xs: 44, sm: 57, md: 70},*/}
            {/*                            fontSize: "20px",*/}
            {/*                            color: "white",*/}
            {/*                            "&:after": {*/}
            {/*                                borderColor: "primary6.main",*/}
            {/*                            },*/}
            {/*                            "&:before": {*/}
            {/*                                borderColor: "primary6.main",*/}
            {/*                            }*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    sx={{*/}
            {/*                        width: "100%",*/}
            {/*                        "& .MuiInputBase-input": {*/}
            {/*                            fontSize: {xs: "14px", sm: "20px", md: "26px"}*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} sx={{mt: 3}}>*/}
            {/*                <Typography sx={{*/}
            {/*                    fontSize: "13px",*/}
            {/*                    color: "white",*/}
            {/*                    display: "flex",*/}
            {/*                    alignItems: "center"*/}
            {/*                }}> <EmojiObjects sx={{color: "error.main", mr: 1, mb: "2px"}}/>Please ensure that the*/}
            {/*                    address belongs*/}
            {/*                    to the receiver, NOT to an exchange or custodial wallet.*/}
            {/*                </Typography>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </Box>) : (<></>)*/}
            {/*}*/}
            {/*<Box sx={{*/}
            {/*    display: 'flex',*/}
            {/*    flexGrow: 1,*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    borderRadius: '1.25rem',*/}
            {/*                            border: '1px solid #347362',*/}

            {/*    p: {xs: '24px 16px', sm: "24px 40px 28px"},*/}
            {/*    mt: {xs: "12px", md: "24px"}*/}
            {/*}}>*/}
            {/*    <Grid container spacing={2}>*/}
            {/*        <Grid item xs={12} sx={{display: "flex", justifyContent: "start", alignItems: "end"}}>*/}
            {/*            <EmojiObjects sx={{color: "primary5.main"}}/>*/}
            {/*            <Typography component="span"*/}
            {/*                        sx={{color: "primary5.main", fontSize: "13px", fontWeight: 600}}>Notes:</Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} sx={{pt: "0px !important"}}>*/}
            {/*            <Typography sx={{*/}
            {/*                pr: {xs: "16px", md: "32px"},*/}
            {/*                pl: {xs: "16px", md: "32px"},*/}
            {/*                fontSize: "13px",*/}
            {/*                color: "primary5.main"*/}
            {/*            }}><FiberManualRecord*/}
            {/*                sx={{fontSize: "6px", mr: 1}}/>Crosschain Fee is 0.00%, Gas fee 0.00 {mainCurrency.iso}*/}
            {/*            </Typography>*/}
            {/*            /!*<Typography sx={{*!/*/}
            {/*            /!*    pr: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    pl: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    fontSize: "13px",*!/*/}
            {/*            /!*    color: "white"*!/*/}
            {/*            /!*}}><FiberManualRecord*!/*/}
            {/*            /!*    sx={{fontSize: "6px", mr: 1}}/>Minimum Crosschain Amount is 750 {mainCurrency.iso}*!/*/}
            {/*            /!*</Typography>*!/*/}
            {/*            /!*<Typography sx={{*!/*/}
            {/*            /!*    pr: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    pl: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    fontSize: "13px",*!/*/}
            {/*            /!*    color: "white"*!/*/}
            {/*            /!*}}><FiberManualRecord*!/*/}
            {/*            /!*    sx={{fontSize: "6px", mr: 1}}/>Maximum Crosschain Amount is 84,000,000 {mainCurrency.iso}*!/*/}
            {/*            /!*</Typography>*!/*/}
            {/*            <Typography sx={{*/}
            {/*                pr: {xs: "16px", md: "32px"},*/}
            {/*                pl: {xs: "16px", md: "32px"},*/}
            {/*                fontSize: "13px",*/}
            {/*                color: "primary5.main"*/}
            {/*            }}><FiberManualRecord*/}
            {/*                sx={{fontSize: "6px", mr: 1}}/>Estimated Time of Crosschain Arrival is 1-3*/}
            {/*                min</Typography>*/}
            {/*            /!*<Typography sx={{*!/*/}
            {/*            /!*    pr: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    pl: {xs: "16px", md: "32px"},*!/*/}
            {/*            /!*    fontSize: "13px",*!/*/}
            {/*            /!*    color: "white"*!/*/}
            {/*            /!*}}><FiberManualRecord*!/*/}
            {/*            /!*    sx={{fontSize: "6px", mr: 1}}/>Crosschain amount larger than*!/*/}
            {/*            /!*    17,000,000 {mainCurrency.iso} could take up*!/*/}
            {/*            /!*    to 12 hours</Typography>*!/*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}
            {/*<Box sx={{*/}
            {/*    display: 'flex',*/}
            {/*    flexGrow: 1,*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    mt: {xs: "12px", md: "24px"}*/}
            {/*}}>*/}
            {/*    <Button variant="contained" color="primary" onClick={onTransferBalancePressed} sx={{*/}
            {/*        width: 320,*/}
            {/*        height: 54,*/}
            {/*        borderRadius: 20,*/}
            {/*        fontWeight: "bold",*/}
            {/*        boxShadow: 0,*/}
            {/*    }}>Bridge</Button>*/}
            {/*</Box>*/}
            <Dialog
                open={openCurrencyModal}
                onClose={handleCurrencyModal}
                sx={{
                    "& .MuiPaper-root": {
                        width: 420,
                        maxWidth: 420,
                        maxHeight: "80vh",
                        bgcolor: 'grey8.main',
                        color: "white",
                        borderRadius: "20px",
                    }
                }}
            >
                <DialogTitle>
                    <Typography
                        component="div"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: "white"
                        }}
                    >
                        <div>Select a token</div>
                        <Clear sx={{fontSize: "24px", cursor: "pointer"}} onClick={handleCurrencyModal}/>
                    </Typography>
                    <TextField
                        placeholder="Search name"
                        onChange={handleFilterCurrency}
                        sx={{
                            width: "100%",
                            pt: "12px",
                            "& .MuiInputBase-root": {
                                "& .MuiInputBase-input": {color: "white"},
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "20px",
                                    borderColor: "white"
                                }
                            },
                            "& .Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "1px !important",
                                    borderColor: "white !important"
                                },
                            }
                        }}/>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        borderColor: "white",
                        p: "0",
                        '&::-webkit-scrollbar': {display: 'none'}
                    }}
                >
                    <List sx={{pt: 0}}>
                        {currencyList.map((token, index) => (
                            <ListItem sx={{
                                justifyContent: "space-between",
                                pr: "24px",
                                pl: "24px",
                                "&:hover": {backgroundColor: "primary4.main"}
                            }} button onClick={() => handleCurrencyListItemClick(token)} key={index}>
                                <Typography component="div" sx={{display: "flex", alignItems: "center"}}>
                                    <Avatar
                                        alt=""
                                        src={token.icon}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            bgcolor: "white",
                                            marginRight: "15px"
                                        }}
                                    />
                                    <div>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            sx={{
                                                color: "white",
                                                fontSize: "1rem",
                                                fontWeight: 400
                                            }}
                                        >{token.iso}</Typography>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            sx={{color: "white"}}
                                        >{token.name}</Typography>
                                    </div>
                                </Typography>
                                <Typography component="div" sx={{color: "white"}}>{accountBalance}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openNetworkModal}
                onClose={() => handleNetworkModal("")}
                sx={{
                    "& .MuiPaper-root": {
                        width: 420,
                        maxWidth: 420,
                        maxHeight: "80vh",
                        bgcolor: 'grey8.main',
                        color: "white",
                        borderRadius: "20px",
                    }
                }}
            >
                <DialogTitle>
                    <Typography
                        component="div"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: "white"
                        }}
                    >
                        <div>Switch to</div>
                        <Clear sx={{fontSize: "24px", cursor: "pointer"}} onClick={() => handleNetworkModal("")}/>
                    </Typography>
                    <TextField
                        placeholder="Switch to"
                        onChange={handleFilterNetwork}
                        sx={{
                            width: "100%",
                            pt: "12px",
                            "& .MuiInputBase-root": {
                                "& .MuiInputBase-input": {color: "white"},
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "20px",
                                    borderColor: "white"
                                }
                            },
                            "& .Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "1px !important",
                                    borderColor: "white !important"
                                },
                            }
                        }}/>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        borderColor: "white",
                        p: "0",
                        '&::-webkit-scrollbar': {display: 'none'}
                    }}
                >
                    <List sx={{pt: 0}}>
                        {networkDirection === "fromNetwork" ? networkList.map((token, index) => (
                            <ListItem sx={{
                                justifyContent: "space-between",
                                pr: "24px",
                                pl: "24px",
                                borderBottom: "1px solid",
                                borderBottomColor: "grey10.main",
                                "&:hover": {backgroundColor: "grey9.main"}
                            }} button onClick={() => handleNetworkListItemClick(token)} key={index}>
                                <Box sx={{display: "flex", alignItems: "center"}}>
                                    <Avatar
                                        alt=""
                                        src={token.icon}
                                        sx={{
                                            bgcolor: "white.main",
                                            marginRight: "15px"
                                        }}
                                    />
                                    <Box sx={{
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: 'primary6.main',
                                        mr: '6px'
                                    }}/>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        sx={{
                                            color: "white",
                                            fontSize: "1rem",
                                            fontWeight: 400
                                        }}
                                    >{token.name}</Typography>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        sx={{
                                            color: "grey5.main",
                                            fontSize: "0.8rem",
                                            fontWeight: 400,
                                            ml: 1
                                        }}
                                    >{token.caption}</Typography>
                                </Box>
                            </ListItem>
                        )) : toNetworkList.map((token, index) => (
                            <ListItem sx={{
                                justifyContent: "space-between",
                                pr: "24px",
                                pl: "24px",
                                borderBottom: "1px solid",
                                borderBottomColor: "grey10.main",
                                "&:hover": {backgroundColor: "grey9.main"}
                            }} button onClick={() => handleNetworkListItemClick(token)} key={index}>
                                <Box sx={{display: "flex", alignItems: "center"}}>
                                    <Avatar
                                        alt=""
                                        src={token.icon}
                                        sx={{
                                            bgcolor: "white.main",
                                            marginRight: "15px"
                                        }}
                                    />
                                    <Box sx={{
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: 'primary6.main',
                                        mr: '6px'
                                    }}/>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        sx={{
                                            color: "white",
                                            fontSize: "1rem",
                                            fontWeight: 400
                                        }}
                                    >{token.name}</Typography>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        sx={{
                                            color: "grey5.main",
                                            fontSize: "0.8rem",
                                            fontWeight: 400,
                                            ml: 1
                                        }}
                                    >{token.caption}</Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openConfirmTransaction}
                sx={{
                    "& .MuiPaper-root": {
                        width: 467,
                        maxWidth: 467,
                        bgcolor: 'grey8.main',
                        color: "white",
                        borderRadius: "24px",
                        border: "1px solid #719D91",
                    }
                }}
            >
                <DialogContent sx={{textAlign: "center"}}>
                    {!statusOfConfirmTransaction && <Avatar src={loading}
                                                            sx={{
                                                                width: "60px",
                                                                height: "60px",
                                                                mx: 'auto',
                                                                color: "info.main",
                                                                animation: "animate 3s infinite",
                                                                "@keyframes animate": {
                                                                    from: {transform: "rotate(0deg)"},
                                                                    to: {transform: "rotate(360deg)"}
                                                                }
                                                            }}/>}
                    {statusOfConfirmTransaction && <DoneAllSharpIcon sx={{
                        fontSize: "65px",
                        color: "success.main",
                    }}/>}
                    {!statusOfConfirmTransaction && <Box>
                        <Typography
                            variant="caption"
                            component="p"
                            sx={{
                                mt: 3,
                                fontSize: "16px",
                                fontWeight: "600",
                                pb: "12px"
                            }}>Please wait ...</Typography>
                        <Typography
                            variant="caption"
                            component="p"
                            sx={{
                                mt: 0,
                                color: 'grey5.main',
                                fontSize: "16px",
                                fontWeight: "600",
                                pb: 0
                            }}>The transaction to be confirmed by the network.</Typography>
                    </Box>}
                    {statusOfConfirmTransaction && <Typography
                        variant="caption"
                        component="p"
                        sx={{
                            mt: 3,
                            fontSize: "16px",
                            fontWeight: "600",
                            pb: "12px"
                        }}>Your transaction confirmed successfully. Please continue the Bridge process from your
                        metamask wallet.</Typography>}
                    {statusOfConfirmTransaction && <Button variant="text" onClick={handleConfirmTransaction} sx={{
                        bgcolor: "white",
                        color: "primary.main",
                        width: 70,
                        height: 36,
                        borderRadius: 20,
                        mt: 3,
                        textTransform: "none",
                        fontWeight: "bold",
                        "&:hover": {
                            bgcolor: "secondary.main"
                        }
                    }}>Got It</Button>}
                </DialogContent>
            </Dialog>
            <Snackbar
                open={approveOpenToast}
                autoHideDuration={6000}
                key={approveStatus.status}
                onClose={handleCloseApproveToast}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Alert
                    onClose={handleCloseApproveToast}
                    severity={approveStatus.code === 200 ? "success" : "error"}
                    sx={{width: '100%'}}
                >{approveStatus.status}</Alert>
            </Snackbar>
            <Snackbar
                open={depositOpenToast}
                autoHideDuration={6000}
                key={depositStatus.status}
                onClose={handleCloseDepositToast}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Alert
                    onClose={handleCloseDepositToast}
                    severity={depositStatus.code === 200 ? "success" : "error"}
                    sx={{width: '100%'}}
                >{depositStatus.status}</Alert>
            </Snackbar>
        </>
    );
};

export default Bridge;