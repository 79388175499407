import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {routes} from "./configs/routes";
import {styled} from '@mui/material/styles';
import Layout from "./layouts/default";
import {Box} from "@mui/material";
import {getAddress, getDefaultNetwork, setWalletAddress} from "./store/features/walletSlice";
import {useAppDispatch} from './store/type';

declare var window: any;

const AppWrapper = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.grey11.main,
    backgroundImage:
        'url("/layout-bg.svg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
}));

function App() {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(getAddress());
        dispatch(getDefaultNetwork());
        changeAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeAccount = () => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts: string[]) => {
                dispatch(setWalletAddress(accounts[0]));
            });
        }
    };
    return (
        <AppWrapper>
            <Router>
                <Layout >
                    <Box  sx={{margin: {xs: "0 12px", md: '0 58px'}}}>
                        <Routes>
                            {routes.map(route => (
                                <Route
                                    key={route.key}
                                    path={route.path}
                                    element={<route.component/>}
                                />
                            ))}
                        </Routes>
                    </Box>
                </Layout>
            </Router>
        </AppWrapper>
    );
}

export default App;
