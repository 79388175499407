import RouteItem from "../models/RouteItem.model";

// import WalletConnect from "../pages/WalletConnect";
import Dashboard from "../pages/Dashboard";
import Bridge from "../pages/Bridge";
import Stake from "../pages/Stake";
// import Airdrop from "../pages/Airdrop";

export const routes: Array<RouteItem> = [
    {
        key: 'dashboard-route',
        title: 'Dashboard',
        path: '/',
        enabled: true,
        component: Dashboard,
        icon: 'Dashboard',
    },
    // {
    //     key: 'wallet-connect-route',
    //     title: 'Wallet Connect',
    //     path: '/connect',
    //     enabled: true,
    //     component: WalletConnect,
    //     icon: 'WalletConnect',
    // },
    // {
    //     key: 'airdrop-route',
    //     title: 'Airdrop',
    //     path: '/airdrop',
    //     enabled: true,
    //     component: Airdrop,
    //     icon: 'CardGiftcard',
    // },
    {
        key: 'bridge-route',
        title: 'Bridge',
        path: '/bridge',
        enabled: true,
        component: Bridge,
        icon: 'JoinFull',
    },
    {
        key: 'Stake-route',
        title: 'Stake',
        path: '/stake',
        enabled: true,
        component: Stake,
        icon: 'CurrencyExchange'
    }
    // {
    //     key: 'pool-route',
    //     title: 'Pool',
    //     path: '/pool',
    //     enabled: true,
    //     component: Pool,
    //     icon: 'PanoramalFishEye'
    // }
]